var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "managementList" },
    [
      _c("div", { staticClass: "managementHeader" }, [
        _c("div", { staticClass: "headerleft" }, [
          _vm._v("\n      " + _vm._s(_vm.groupName) + "\n      "),
          _c("span", {
            staticClass: "iconfont_Me icon-edit1 iconStyle",
            on: { click: _vm.openChangeTeamNameDialog }
          })
        ]),
        _vm.notVerifying
          ? _c(
              "div",
              { staticClass: "headerRight", on: { click: _vm.startVerify } },
              [
                _c("div", { staticClass: "startVerify" }, [
                  _c("img", {
                    style: { width: "24px", height: "24px" },
                    attrs: {
                      src: require("../../../assets/images/teamManagement/email_icon.png")
                    }
                  }),
                  _c("div", { staticClass: "activateBusinessEmail" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("activateBusinessEmailServices")) +
                        "\n        "
                    )
                  ]),
                  _c("span", {
                    staticClass: "iconfont_Me icon-right iconStyle"
                  })
                ])
              ]
            )
          : _c(
              "div",
              {
                staticClass: "headerRight",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.goDomainManagement.apply(null, arguments)
                  }
                }
              },
              [
                _vm._v("\n      @" + _vm._s(_vm.mailDomain) + "\n      "),
                _vm.isVerifying && !_vm.emailMigrationInProgress
                  ? _c("span", { staticClass: "verifying" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("isVerifying")) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _vm.emailMigrationInProgress
                  ? _c("span", { staticClass: "verifying" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("emailMigrationInProgress")) +
                          "\n      "
                      )
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "iconfont_Me icon-right iconStyle" })
              ]
            )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.teamLoading,
              expression: "teamLoading"
            }
          ],
          staticClass: "managementBox",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _c("div", { staticClass: "searchAndAddBox" }, [
            _c(
              "div",
              { staticClass: "searchBox" },
              [
                _c("el-input", {
                  attrs: {
                    resize: "none",
                    maxlength: "200",
                    placeholder: _vm.placeholderIcon
                  },
                  on: { input: _vm.searchChange },
                  model: {
                    value: _vm.searchText,
                    callback: function($$v) {
                      _vm.searchText = $$v
                    },
                    expression: "searchText"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "addBox",
                on: { click: _vm.openCreateMemberMethodDialog }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/teamManagement/addMember.png")
                  }
                })
              ]
            )
          ]),
          _c("MembersTable", {
            attrs: { members: _vm.members, mailDomain: _vm.mailDomain },
            on: {
              openMemberInfoDialog: _vm.openMemberInfoDialog,
              openCreatedEmailOrIDSuccessDialog:
                _vm.openCreatedEmailOrIDSuccess_method_1,
              openMemberRecordDialog: _vm.openMemberRecordDialog,
              changeFreezeStatus: _vm.changeFreezeStatus,
              unbindEmail: _vm.unbindEmail
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "membersPagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "page-size": _vm.pageSize,
              total: _vm.dataTotal,
              "current-page": _vm.currentPage
            },
            on: {
              "current-change": _vm.currentChange,
              "update:currentPage": function($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function($event) {
                _vm.currentPage = $event
              }
            }
          })
        ],
        1
      ),
      _vm.createMemberMethodDialog
        ? [
            _c("CreateMemberMethodDialog", {
              attrs: { createMemberMethodDialog: _vm.createMemberMethodDialog },
              on: {
                closeCreateMemberMethodDialog: _vm.closeCreateMemberMethodDialog
              }
            })
          ]
        : _vm._e(),
      _vm.showChangeTeamNameDialog
        ? [
            _c("ChangeTeamNameDialog", {
              attrs: {
                showChangeTeamNameDialog: _vm.showChangeTeamNameDialog,
                groupNameInDialog: _vm.groupNameInDialog
              },
              on: {
                closeChangeTeamNameDialog: _vm.closeChangeTeamNameDialog,
                changeTeamName: _vm.changeTeamName
              }
            })
          ]
        : _vm._e(),
      _vm.showAddMemberDialog
        ? [
            _c("AddMemberDialog", {
              attrs: { showAddMemberDialog: _vm.showAddMemberDialog },
              on: { closeAddMemberDialog: _vm.closeAddMemberDialog }
            })
          ]
        : _vm._e(),
      _vm.showMemberInfoDialog
        ? [
            _c("MemberInfoDialog", {
              attrs: {
                showMemberInfoDialog: _vm.showMemberInfoDialog,
                selectedRow: _vm.selectedRow
              },
              on: { closeMemberInfoDialog: _vm.closeMemberInfoDialog }
            })
          ]
        : _vm._e(),
      _vm.showMemberRecordDialog
        ? [
            _c("MemberRecordDialog", {
              attrs: {
                showMemberRecordDialog: _vm.showMemberRecordDialog,
                selectedRow: _vm.selectedRow
              },
              on: { closeMemberRecordDialog: _vm.closeMemberRecordDialog }
            })
          ]
        : _vm._e(),
      _vm.showFreezeStatusDialog
        ? [
            _c("FreezeStatusDialog", {
              attrs: {
                showFreezeStatusDialog: _vm.showFreezeStatusDialog,
                selectedRow: _vm.selectedRow
              },
              on: { closeFreezeStatusDialog: _vm.closeFreezeStatusDialog }
            })
          ]
        : _vm._e(),
      _vm.createIDAndEmailDialog
        ? [
            _c("CreateIDAndEmailDialog", {
              attrs: { createIDAndEmailDialog: _vm.createIDAndEmailDialog },
              on: {
                closeCreateIDAndEmailDialog: _vm.closeCreateIDAndEmailDialog,
                confirm: _vm.createIDAndEmailConfirm
              }
            })
          ]
        : _vm._e(),
      _vm.myTeamMembersDialog
        ? [
            _c("MyTeamMembersDialog", {
              attrs: { myTeamMembersDialog: _vm.myTeamMembersDialog },
              on: {
                closeMyTeamMembersDialog: _vm.closeMyTeamMembersDialog,
                selectMember: _vm.selectMember
              }
            })
          ]
        : _vm._e(),
      _vm.createEmailForMemberDialog
        ? [
            _c("CreateEmailForMemberDialog", {
              attrs: {
                createEmailForMemberDialog: _vm.createEmailForMemberDialog,
                memberInfo: _vm.createEmail_memberInfo
              },
              on: {
                closeCreateEmailForMemberDialog:
                  _vm.closeCreateEmailForMemberDialog,
                confirm: _vm.createEmailForMemberConfirm
              }
            })
          ]
        : _vm._e(),
      _vm.createdEmailOrIDSuccessDialog
        ? [
            _c("CreatedEmailOrIDSuccessDialog", {
              attrs: {
                from: _vm.createdEmailOrIDSuccessFrom,
                memberInfo: _vm.createdEmailOrIDSuccessMemberInfo,
                createdEmailOrIDSuccessDialog: _vm.createdEmailOrIDSuccessDialog
              },
              on: {
                closeCreatedEmailOrIDSuccessDialog:
                  _vm.closeCreatedEmailOrIDSuccessDialog,
                sendNotifications: _vm.sendNotifications
              }
            })
          ]
        : _vm._e(),
      _vm.notificationForMemberDialog
        ? [
            _c("NotificationForMemberDialog", {
              attrs: {
                notificationForMemberDialog: _vm.notificationForMemberDialog,
                notificationInfo: _vm.notificationForMemberInfo
              },
              on: {
                closeNotificationForMemberDialog: function($event) {
                  _vm.notificationForMemberDialog = false
                }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }