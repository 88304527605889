<template>
  <el-dialog
    :visible.sync="notificationForMemberDialog"
    width="640px"
    class="notificationForMemberDialog"
    :close-on-click-modal="false"
    :before-close="closeNotificationForMemberDialog"
    :destroy-on-close="true"
  >
    <div class="dialogIcon">
      {{ $t("noticeContent") }}
    </div>
    <div class="dialogBody">
      <div
        class="contentBox"
        ref="contentBox"
        :style="{
          width: '100%',
          flex: '1',
          'min-height': '0',
          display: 'flex',
          'align-items': 'center',
          'flex-direction': 'column',
          'justify-content': 'flex-start',
        }"
      >
        <div
          :style="{
            'font-size': '18px',
            color: '#292D32',
            'line-height': '25px',
            'text-align': 'left',
          }"
        >
          {{ $t("teamMemberNotification", { n: melinkedID }) }}
        </div>
        <div
          :style="{
            width: '100%',
            'margin-top': '28px',
            'font-size': '18px',
            color: '#292D32',
            'line-height': '25px',
            'text-align': 'left',
            'font-weight': 'bold',
          }"
        >
          <div
            :style="{
              'margin-bottom': '6px',
            }"
          >
            {{ $t("emailAddress") }}:{{ emailAddress }}
          </div>
          <div
            :style="{
              'margin-bottom': '6px',
            }"
          >
            {{ $t("emailPassword") }}:{{ emailPassword }}
          </div>
        </div>
      </div>
      <div class="buttonBox">
        <div class="buttonStyle" v-waves @click.stop="sendNotification">
          <i class="iconfont_Me icon-send iconStyle"></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CommonInput from "../../../components/inputComponent/commonInput.vue";
import { sendNotification } from "../../../../api/newVersion/team/index";
export default {
  name: "NotificationForMemberDialog",
  components: {
    CommonInput,
  },
  props: {
    notificationForMemberDialog: {
      type: Boolean,
      default: false,
    },
    notificationInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    melinkedID() {
      let melinkedID = "";
      if (this.notificationInfo) {
        melinkedID = this.notificationInfo.ucId;
      }
      return melinkedID;
    },
    emailAddress() {
      let emailAddress = "";
      if (this.notificationInfo) {
        const email = this.notificationInfo.emails.filter(
          (email) => email.bindCondition == 3
        )[0];
        emailAddress = email.mailAddress;
      }
      return emailAddress;
    },
    emailPassword() {
      let emailPassword = "";
      if (this.notificationInfo) {
        const email = this.notificationInfo.emails.filter(
          (email) => email.bindCondition == 3
        )[0];
        emailPassword = email.initPassword;
      }
      return emailPassword;
    },
  },
  data() {
    return {};
  },
  methods: {
    closeNotificationForMemberDialog() {
      this.$emit("closeNotificationForMemberDialog");
    },
    // 点击发送通知按钮
    async sendNotification() {
      const message = {
        detail: this.$refs.contentBox.outerHTML.replace(/\s{2,}/g, "").trim(),
        tableText: this.$t("teamMemberNotification", { n: this.melinkedID }),
      };
      // 将字符串中的空格和换行取消
      const param = {
        message: JSON.stringify(message),
        providerIds: [this.notificationInfo.providerId],
        title: "",
      };
      const result = await sendNotification(param);
      if (result.code == 200) {
        this.$message({
          customClass: "meetingMessage",
          dangerouslyUseHTMLString: true,
          message:
            '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
            '<div class="copySucceeded"></div>' +
            "<span>" +
            this.$t("successfullySent") +
            "</span>" +
            "</div>",
          duration: 1000,
        });
        this.$emit("closeNotificationForMemberDialog");
      } else {
        this.$message({
          customClass: "meetingMessage",
          dangerouslyUseHTMLString: true,
          message:
            '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
            '<div class="copyFaild"></div>' +
            "<span>" +
            this.$t("sendingNotificationFailed") +
            "</span>" +
            "</div>",
          duration: 1000,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.notificationForMemberDialog
  .dialogIcon
    height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 35px;
    color: #292D32;
    font-size: 24px;
    line-height: 28px;
  .dialogBody
    width: 100%;
    flex 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    .buttonBox
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      .buttonStyle
        width: 100%;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #52BD68;
        .iconStyle
          font-size: 24px;
          color: #FFFFFF;
</style>
<style lang="stylus">
.notificationForMemberDialog
  .el-dialog
    margin-top 0 !important;
    padding 40px 80px 50px 80px !important;
    height: 460px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      padding 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
</style>
