<template>
  <el-dialog
    :visible.sync="myTeamMembersDialog"
    width="560px"
    center
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :append-to-body="true"
    custom-class="myTeamMembersDialog"
    :top="'0'"
    :before-close="closeDialog"
  >
    <div class="dialogBody">
      <span
        class="iconfont_Me icon-x closeDialogStyle"
        @click.stop="closeDialog"
      ></span>
      <div class="dialogHeader">{{ $t("myTeamMembers") }}</div>
      <div class="listArea">
        <div class="listBox">
          <div class="boxHeader">
            <div class="searchInput">
              <span
                class="iconfont_Me icon-search iconStyle"
                @click.stop="getMembersList()"
              ></span>
              <el-input
                v-model="searchKey"
                @keyup.native.enter="getMembersList()"
              ></el-input>
            </div>
          </div>
          <div class="listCoreBox" @scroll="coreBoxScroll">
            <template v-if="membersList.length != 0">
              <div
                class="listItem"
                v-for="data in membersList"
                :key="data.ucId"
                @click.stop="selectedThisItem(data)"
                :style="{
                  cursor: data.freezeStatus ? 'not-allowed' : 'pointer',
                }"
              >
                <img class="itemImage" :src="handlerImage(data.ucId)" />
                <div class="userPhoto">
                  <Avatar
                    :path="data.profilePhoto"
                    :international="
                      data.country == '' ? 0 : toNumber(data.country)
                    "
                    :isFrozen="data.freezeStatus"
                    :isOwnerOfTheTeam="data.identity == 4 ? true : false"
                  >
                  </Avatar>
                </div>
                <div class="itemTitle">ID:{{ data.ucId }}</div>
              </div>
            </template>
            <div class="placeholderMap" v-else>
              <PlaceholderMap :placeholderType="2"></PlaceholderMap>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div
          class="buttonStyle confirm"
          v-waves
          @click.stop="confirm"
          :class="!selectedItem ? 'disableStyle' : ''"
        >
          <span class="iconfont_Me icon-check iconStyle"></span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import Avatar from "../../../components/avatarDisplay/index.vue";
import PlaceholderMap from "@/newVersion/components/placeholderMap";
import { getUnbindEmailMemberList } from "@/api/newVersion/team/index";
export default {
  name: "MyTeamMembersDialog",
  components: {
    Avatar,
    PlaceholderMap,
  },
  props: {
    myTeamMembersDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchKey: "",
      currentPage: 1,
      pageSize: 20,
      loadDataDisabled: false,
      membersList: [],
      selectedItem: null,
    };
  },
  async mounted() {
    this.loadDataDisabled = true;
    await this.getMembersList();
    this.loadDataDisabled = false;
  },
  methods: {
    selectedThisItem(value) {
      if (value.freezeStatus) {
        return;
      }
      this.selectedItem = value;
    },
    // 字符串转数字
    toNumber(str) {
      return parseInt(str);
    },
    handlerImage(value) {
      if (this.selectedItem && this.selectedItem.ucId == value) {
        return require("../../../../assets/images/teamManagement/selected.png");
      } else {
        return require("../../../../assets/images/teamManagement/notSelected.png");
      }
    },
    coreBoxScroll(e) {
      const scrollTop = e.target.scrollTop; // 滚动距离顶部的距离
      const clientHeight = e.target.clientHeight; // 滚动区域高度
      const scrollHeight = e.target.scrollHeight; // 滚动内容高度
      if (
        scrollTop + clientHeight + 10 >= scrollHeight &&
        !this.loadDataDisabled
      ) {
        this.loadData();
      }
    },
    async loadData() {
      this.currentPage++;
      this.loadDataDisabled = true;
      await this.getMembersList();
      this.loadDataDisabled = false;
    },
    confirm() {
      if (this.selectedItem != null) {
        this.$emit("selectMember", _.cloneDeep(this.selectedItem));
      }
      this.$nextTick(() => {
        this.closeDialog();
      });
    },
    async getMembersList(
      params = {
        pageSize: this.pageSize,
        page: this.currentPage,
        searchWords: this.searchKey,
      }
    ) {
      this.selectedItem = null;
      const result = await getUnbindEmailMemberList(params);
      if (result.code == 200) {
        if (this.currentPage == 1) {
          this.membersList = result.data.data;
        } else {
          if (result.data.data.length > 0) {
            this.membersList = this.membersList.concat(result.data.data);
          } else {
            this.currentPage--;
          }
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async closeDialog() {
      this.$emit("closeMyTeamMembersDialog");
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.myTeamMembersDialog
  .dialogBody
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .closeDialogStyle
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;
      font-size: 20px;
      cursor: pointer;
    .dialogHeader
      width: 100%;
      text-align: center;
      font-size: 20px;
      color: #000000;
      padding-top: 36px;
      user-select: none;
      flex-shrink: 0;
    .listArea
      padding: 24px;
      box-sizing: border-box;
      flex: 1;
      min-height: 0;
      width: 100%;
      .listBox
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #B3B3B3;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        .boxHeader
          width: 100%;
          height: 50px;
          flex-shrink: 0;
          box-sizing: border-box;
          border-bottom: 1px solid #B3B3B3;
          padding: 0 8px 0 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .searchInput
            width: 160px;
            height: 32px;
            border-radius: 16px;
            box-sizing: border-box;
            border: 1px solid #D9D9D9;
            background: #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .iconStyle
              font-size: 16px;
              color: #8F8F8F;
              margin-left: 8px;
              cursor: pointer;
              flex-shrink: 0;
            :deep .el-input
              height: 100%;
              flex: 1;
              min-width: 0;
              margin-right: 8px;
              .el-input__inner
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 8px;
                background: transparent;
        .listCoreBox
          flex: 1;
          min-height: 0;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          scrollbar-light();
          .placeholderMap
            width: 100%;
            height: 100%;
            overflow: hidden;
          .listItem
            width: 100%;
            min-height: 50px;
            box-sizing: border-box;
            border-bottom: 1px solid #F0F0F0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: background 0.3s;
            &:hover
              background: #E6E6E6;
              cursor: pointer;
            .itemImage
              width: 20px;
              height: 20px;
              flex-shrink: 0;
              margin:0 50px 0 40px;
            .userPhoto
              width: 50px;
              height: 50px;
              margin-right: 12px;
            .itemTitle
              flex: 1;
              min-width: 0;
              height: 100%;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: flex-start;
    .footer
      width: 100%;
      height: 48px;
      margin-bottom: 24px;
      flex-shrink: 0;
      box-sizing: border-box;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .disableStyle
        background: #D9D9D9 !important;
        cursor: not-allowed !important;
      .buttonStyle
        height: 100%;
        width: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #B3B3B3;
        cursor: pointer;
        .iconStyle
          font-size: 20px;
          color: #B3B3B3;
      .confirm
        background: #52BD68;
        border: none;
        .iconStyle
          color: #FFFFFF;
</style>
<style lang="stylus">
.myTeamMembersDialog
  padding: 0;
  border-radius: 5px;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding: 0;
    height: 650px;
    max-height: 90vh;
</style>
