<template>
  <el-dialog
    :visible.sync="showFreezeStatusDialog"
    width="480px"
    class="freezeStatusDialog"
    :close-on-click-modal="false"
    :before-close="closeFreezeStatusDialog"
    :destroy-on-close="true"
  >
    <div
      class="dialogBody"
      v-loading="dialogLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div class="dialogHeader" v-if="selectedRow">
        {{ dialogTitle }}
      </div>
      <div class="buttonBox">
        <div class="buttonStyle" @click.stop="closeFreezeStatusDialog">
          <span class="iconfont_Me icon-x iconStyle"></span>
        </div>
        <div
          class="buttonStyle commomButtonHover"
          @click.stop="change"
          :style="{ 'margin-left': '10px' }"
        >
          <span class="iconfont_Me icon-check iconStyle"></span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { freezeMember, defreezeMember } from "@/api/newVersion/team";
export default {
  name: "FreezeStatusDialog",
  props: {
    showFreezeStatusDialog: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    dialogTitle() {
      return this.selectedRow.freezeStatus
        ? this.$t("unfreeze")
        : this.$t("frozen");
    },
  },
  data() {
    return {
      dialogLoading: false,
    };
  },
  methods: {
    closeFreezeStatusDialog() {
      this.$emit("closeFreezeStatusDialog");
    },
    async change() {
      if (this.selectedRow) {
        const providerId = this.selectedRow.providerId;
        this.dialogLoading = true;
        const result = this.selectedRow.freezeStatus
          ? await defreezeMember(providerId)
          : await freezeMember(providerId);
        this.dialogLoading = false;
        if (result.code == 200) {
          this.$emit("closeFreezeStatusDialog", true);
        } else {
          this.closeFreezeStatusDialog();
          this.$message({
            type: "error",
            message: result.message || result.data.message,
          });
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.freezeStatusDialog
  .dialogBody
    width: 100%;
    height: 100%;
    overflow hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .dialogHeader
      width: 100%;
      padding-top 40px;
      text-align: center;
      font-size: 16px;
    .buttonBox
      width: 100%;
      height: 34px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .buttonStyle
        width: 90px;
        height: 100%;
        border-radius: 4px;
        background: #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor pointer;
        .iconStyle
          font-size: 22px;
          color: #FFFFFF;
</style>
<style lang="stylus">
.freezeStatusDialog
  .el-dialog
    margin-top 0 !important;
    padding 0 !important;
    height: 240px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
</style>
