var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "addMemberDialog",
      attrs: {
        visible: _vm.showAddMemberDialog,
        width: "480px",
        "close-on-click-modal": false,
        "before-close": _vm.closeAddMemberDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showAddMemberDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogIcon" }, [
        _c("div", { staticClass: "icon" }, [
          _c("span", { staticClass: "iconfont_Me icon-user-fill iconStyle" })
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ],
          staticClass: "dialogBody",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _c("div", { staticClass: "input" }, [
            _c("div", { staticClass: "inputTitle" }, [_vm._v("Melinked ID")]),
            _c(
              "div",
              { staticClass: "inputBox" },
              [
                _c(
                  "CommonInput",
                  {
                    attrs: {
                      maxlength: 100,
                      defaultInputText: _vm.userName,
                      needInputshowError: _vm.needInputshowError,
                      checkAccountSuccess: _vm.checkAccountSuccess,
                      noChinese: true
                    },
                    on: { inputChange: _vm.getAccountText }
                  },
                  [
                    _c("span", {
                      staticClass: "iconfont_Me icon-user-fill iconStyle"
                    })
                  ]
                )
              ],
              1
            ),
            _vm.needInputshowError
              ? _c("div", { staticClass: "errorBox" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.addTeamMemberTip) + "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "confirmButton",
              class: _vm.checkAccountSuccess ? "commomButtonHover" : "",
              style: {
                cursor: _vm.checkAccountSuccess ? "pointer" : "not-allowed"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirm.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }