<template>
  <el-dialog
    :visible.sync="showChangeTeamNameDialog"
    width="404px"
    class="changeTeamNameDialog"
    :close-on-click-modal="false"
    :before-close="closeChangeTeamNameDialog"
    :destroy-on-close="true"
  >
    <div slot="title">
      {{ $t("teamName") }}
    </div>
    <div class="dialogBody">
      <div class="inputBox">
        <el-input
          resize="none"
          maxlength="200"
          v-model="groupName"
          :placeholder="placeholderIcon"
        ></el-input>
      </div>
      <div class="buttonBox">
        <div class="buttonStyle" @click.stop="closeChangeTeamNameDialog">
          <span class="iconfont_Me icon-x iconStyle"></span>
        </div>
        <div
          class="buttonStyle"
          @click.stop="change"
          :style="{
            'margin-left': '10px',
            cursor: groupName != '' ? 'pointer' : 'not-allowed',
          }"
          :class="groupName != '' ? 'commomButtonHover' : ''"
        >
          <span class="iconfont_Me icon-check iconStyle"></span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { changeTeamName } from "../../../../api/newVersion/team/index";
export default {
  name: "ChangeTeamNameDialog",
  props: {
    groupNameInDialog: {
      type: String,
      default: "",
    },
    showChangeTeamNameDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupName: "",
      // 默认文本
      placeholderIcon: "\ue6a1",
    };
  },
  mounted() {
    this.groupName = this.groupNameInDialog;
  },
  methods: {
    closeChangeTeamNameDialog() {
      this.groupName = "";
      this.$emit("closeChangeTeamNameDialog");
    },
    async change() {
      if (this.groupName == "") {
        return;
      }
      try {
        if (this.globalTrim(this.groupName) !== "") {
          const result = await changeTeamName({
            teamName: this.groupName,
          });
          if (result.code == 200) {
            this.$emit("changeTeamName", this.groupName);
            this.closeChangeTeamNameDialog();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        }
      } catch (error) {}
    },
  },
};
</script>
<style lang="stylus" scoped>
.changeTeamNameDialog
  .dialogBody
    flex 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .inputBox
      width 100%;
      min-height 40px;
      box-sizing border-box;
      border-radius 4px;
      border 1px solid #CCCCCC;
      display flex;
      justify-content center;
    .buttonBox
      width: 100%;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      .buttonStyle
        width: 90px;
        height: 100%;
        border-radius: 4px;
        background: #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor pointer;
        .iconStyle
          font-size: 22px;
          color: #FFFFFF;
</style>
<style lang="stylus">
.changeTeamNameDialog
  .el-dialog
    margin-top 0 !important;
    padding 30px 40px !important;
    height: 308px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      width: 100%;
      padding 25px 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      padding 9px 0 0 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      .el-input
        .el-input__inner
          border none !important;
          font-family 'iconfont_Me';
          caret-color #33CC66;
          font-size 14px !important;
          color #333333 !important;
</style>
