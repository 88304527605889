<template>
  <el-dialog
    :visible.sync="createdEmailOrIDSuccessDialog"
    width="640px"
    class="createdEmailOrIDSuccessDialog"
    :close-on-click-modal="false"
    :before-close="closeCreatedEmailOrIDSuccessDialog"
    :destroy-on-close="true"
  >
    <div class="dialogIcon">
      <img
        class="iconImage"
        :src="
          require('../../../../assets/images/teamManagement/success_icon.png')
        "
      />
      {{ $t("createdSuccessfully") }}
    </div>
    <div class="dialogBody">
      <div class="contentBox">
        <div class="contentItem" :title="melinkedID">
          <span class="itemTitle">Melinked ID:</span>
          {{ melinkedID }}
        </div>
        <div class="contentItem" :title="emailAddress">
          <span class="itemTitle">{{ $t("emailAddress") }}:</span>
          {{ emailAddress }}
        </div>
        <div class="contentItem">
          <span class="itemTitle"
            >{{
              from == "method_1"
                ? `ID/${$t("emailPassword")}`
                : `${$t("emailPassword")}`
            }}:</span
          >
          {{ emailPassword }}
        </div>
      </div>
      <div class="buttonBox">
        <div
          class="buttonStyle leftButtonStyle"
          v-waves
          v-if="from == 'method_2'"
          @click.stop="sendNotifications"
        >
          <img
            :src="
              require('../../../../assets/images/teamManagement/broadcast.png')
            "
            :style="{
              width: '24px',
              height: '24px',
            }"
          />
        </div>
        <div
          class="buttonStyle rightButtonStyle"
          v-waves
          @click.stop="startCopy"
          :style="{
            width: from == 'method_2' ? '' : '100%',
          }"
        >
          <img
            :src="require('../../../../assets/images/teamManagement/copy.png')"
            :style="{
              width: '24px',
              height: '24px',
            }"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CommonInput from "../../../components/inputComponent/commonInput.vue";
export default {
  name: "CreatedEmailOrIDSuccessDialog",
  components: {
    CommonInput,
  },
  props: {
    createdEmailOrIDSuccessDialog: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
      default: "method_1",
    },
    memberInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    melinkedID() {
      let melinkedID = "";
      if (this.memberInfo) {
        melinkedID = this.memberInfo.ucId;
      }
      return melinkedID;
    },
    emailAddress() {
      let emailAddress = "";
      if (this.memberInfo) {
        const email = this.memberInfo.emails.filter(
          (email) => email.bindCondition == 3
        )[0];
        if (email) {
          emailAddress = email.mailAddress;
        } else if (this.memberInfo.emails.length > 0) {
          emailAddress = this.memberInfo.emails[0].mailAddress;
        }
      }
      return emailAddress;
    },
    emailPassword() {
      let emailPassword = "";
      if (this.memberInfo) {
        const email = this.memberInfo.emails.filter(
          (email) => email.bindCondition == 3
        )[0];
        const initPassword =
          email && email.initPassword
            ? email.initPassword
            : this.memberInfo.initPassword;
        emailPassword = initPassword;
      }
      return emailPassword;
    },
  },
  data() {
    return {};
  },
  methods: {
    closeCreatedEmailOrIDSuccessDialog() {
      this.$emit("closeCreatedEmailOrIDSuccessDialog");
    },
    // 点击发送通知按钮
    sendNotifications() {
      this.$emit("sendNotifications", _.cloneDeep(this.memberInfo));
      this.$nextTick(() => {
        this.closeCreatedEmailOrIDSuccessDialog();
      });
    },
    // 点击复制按钮
    startCopy() {
      let copyText = `${this.$t("create_melinked_id_and_copy_email_content")}\r
        URL: https://www.melinked.com\r
        Melinked ID: ${this.melinkedID}\r
        ${this.$t("emailAddress")}: ${this.emailAddress}\r
        ID/${this.$t("emailPassword")}: ${this.emailPassword}\r`;
      if (this.from == "method_2") {
        copyText = `${this.$t("create_email_for_member_content", {
          n: this.memberInfo.name,
        })}\r
        ${this.$t("emailAddress")}: ${this.emailAddress}\r
        ${this.$t("emailPassword")}: ${this.emailPassword}\r`;
      }
      this.$copyText(copyText).then(
        (res) => {
          this.$message({
            customClass: "meetingMessage",
            dangerouslyUseHTMLString: true,
            message:
              '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
              '<div class="copySucceeded"></div>' +
              "<span>" +
              this.$t("copySucceeded") +
              "</span>" +
              "</div>",
            duration: 1000,
          });
          this.closeCreatedEmailOrIDSuccessDialog();
        },
        (err) => {
          this.$message({
            type: "error",
            message: this.$t("copyFailed"),
          });
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.createdEmailOrIDSuccessDialog
  .dialogIcon
    height: 28px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 35px;
    color: #292D32;
    font-size: 20px;
    line-height: 28px;
    .iconImage
      width: 28px;
      height: 28px;
      margin-right: 12px;
  .dialogBody
    width: 100%;
    flex 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    .contentBox
      background: #F5F5F5;
      width: 100%;
      height: 200px;
      margin-bottom: 40px;
      padding: 40px 80px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      .contentItem
        width: 100%;
        min-height: 22px;
        line-height: 22px;
        text-align: left;
        font-size: 16px;
        color: #292D32;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        .itemTitle
          color: #858585;
    .buttonBox
      width: calc(100% - 50px);
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .buttonStyle
        width: 234px;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
      .leftButtonStyle
        border: 1px solid #52BD68;
      .rightButtonStyle
        background: #52BD68;
</style>
<style lang="stylus">
.createdEmailOrIDSuccessDialog
  .el-dialog
    margin-top 0 !important;
    padding 40px 55px 50px 55px !important;
    height: 460px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      padding 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
</style>
