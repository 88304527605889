<template>
  <div class="domain_management">
    <div class="header">
      <div class="header_button">
        <i
          class="iconfont_Me icon-return iconStyle"
          @click.stop="$emit('closeDomainManagement')"
        ></i>
      </div>
      <div class="header_title">
        {{ $t("domainManagement") }}——{{ teamDomainInfo.mailDomain }}
      </div>
      <div class="right_button" v-waves @click.stop="startMigrate">
        {{ $t("mailboxMigrate_1") }}
      </div>
    </div>
    <div class="title">
      {{ $t("ownershipOfTheDomain") }}
    </div>
    <div class="contentTable_1">
      <el-table
        :data="tableData_1"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#F5F5F5',
          'font-weight': '400',
          color: '#858585',
          'font-size': '14px',
        }"
        :row-style="{
          color: '#000000',
          'font-size': '14px',
        }"
        :cell-style="{
          'word-break': 'break-word',
        }"
      >
        <el-table-column
          prop="content_1"
          :label="$t('tableHeader_1')"
          width="180"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_2"
          :label="$t('tableHeader_2')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_3"
          :label="$t('tableHeader_3')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_4"
          :label="$t('tableHeader_4')"
          align="center"
          :resizable="false"
        >
          <template slot-scope="{ row }">
            <div
              :style="{
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
              }"
            >
              <div :style="{ 'word-break': 'break-word' }">
                {{ row.content_4 }}
              </div>
              <i
                class="el-icon-document-copy"
                :style="{
                  color: '#52bd68',
                  'font-size': '18px',
                  'margin-left': '6px',
                  cursor: 'pointer',
                }"
                @click.stop="startCopy(row.content_4)"
              ></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="title">
      {{ $t("DNSConfiguration") }}
    </div>
    <div class="contentTable_2">
      <el-table
        :data="tableData_2"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#F5F5F5',
          'font-weight': '400',
          color: '#858585',
          'font-size': '14px',
        }"
        :row-style="{
          color: '#000000',
          'font-size': '14px',
        }"
        :cell-style="setcellStyle"
      >
        <el-table-column
          prop="column_title"
          :label="''"
          width="180"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_1"
          :label="$t('tableHeader_1')"
          width="180"
          align="center"
          :resizable="false"
        >
          <template slot-scope="{ row }">
            <div
              :style="{
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
              }"
            >
              <div :style="{ 'word-break': 'break-word' }">
                {{ row.content_1.text || row.content_1 }}
              </div>
              <i
                v-show="row.content_1.copy"
                class="el-icon-document-copy"
                :style="{
                  color: '#52bd68',
                  'font-size': '18px',
                  'margin-left': '6px',
                  cursor: 'pointer',
                }"
                @click.stop="startCopy(row.content_1.text)"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content_2"
          :label="$t('tableHeader_2')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_3"
          :label="$t('tableHeader_3')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column
          prop="content_4"
          :label="$t('tableHeader_4')"
          align="center"
          :resizable="false"
        >
          <template slot-scope="{ row }">
            <div
              :style="{
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
              }"
            >
              <div :style="{ 'word-break': 'break-word' }">
                {{ row.content_4.text }}
              </div>
              <i
                v-show="row.content_4.copy"
                class="el-icon-document-copy"
                :style="{
                  color: '#52bd68',
                  'font-size': '18px',
                  'margin-left': '6px',
                  cursor: 'pointer',
                }"
                @click.stop="startCopy(row.content_4.text)"
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="content_5"
          :label="$t('tableHeader_5')"
          width="90"
          align="center"
          :resizable="false"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {
  getDomainRecord,
  openTransferTask,
  getTeamInfo,
} from "@/api/newVersion/team";
export default {
  name: "DomainManagement",
  data() {
    return {
      // 表格数据
      tableData_1: [
        {
          content_1: this.$t("tableContent_1"),
          content_2: "TXT",
          content_3: this.$t("tableContent_3"),
          content_4: "",
        },
      ],
      tableData_2: [
        {
          column_title: "MX",
          key: "@MX",
          content_1: "@",
          content_2: "MX",
          content_3: this.$t("tableContent_3"),
          content_4: {
            text: "",
            copy: true,
          },
          content_5: "10",
        },
        {
          column_title: "SPF",
          key: "@TXT",
          content_1: "@",
          content_2: "TXT",
          content_3: this.$t("tableContent_3"),
          content_4: {
            text: "",
            copy: true,
          },
          content_5: this.$t("tableContent_3"),
        },
      ],
      // 记录初始化的二进制高位记录
      binaryHighBitRecords: null,
    };
  },
  computed: {
    teamDomainInfo() {
      return this.$store.state.team.teamDomainInfo;
    },
    onceAgain() {
      if (typeof this.binaryHighBitRecords == "string") {
        return this.binaryHighBitRecords.substr(2, 2) == "10";
      } else {
        return false;
      }
    },
    // 刷新团队信息
    refreshTeamInfo() {
      return this.$store.state.team.refreshTeamInfo;
    },
  },
  watch: {
    // 刷新团队信息
    refreshTeamInfo: {
      async handler(val, old) {
        if (val && val != old) {
          this.$store.commit("setRefreshTeamInfo", false);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDomainRecord();
  },
  methods: {
    // 开始邮箱迁移
    async startMigrate() {
      const result = await openTransferTask();
      if (result.code == 200) {
        // 更新邮箱信息
        await this.getTeamInfo();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async getTeamInfo(
      params = {
        pageSize: 1,
        page: 1,
        searchWords: "",
      }
    ) {
      const result = await getTeamInfo(params);
      if (result.code == 200) {
        let { mailDomain, mailRegisterStep, migrate } = result.data.data;
        mailRegisterStep = this.processDecimal(mailRegisterStep);
        this.$store.commit("setVerifyDomainDialog", {
          verifyStep: mailRegisterStep,
          domain: mailDomain,
          migrate: migrate,
          binaryHighBitRecords: this.binaryHighBitRecords,
        });
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
    // 处理步骤二进制
    processDecimal(decimalNumber) {
      let binaryString = decimalNumber.toString(2).padStart(12, "0");
      let currentStep = parseInt(binaryString.slice(-4), 2);
      this.binaryHighBitRecords = binaryString.slice(0, -4);
      let completedSteps = [];
      let incompleteSteps = [];
      for (let i = binaryString.length - 5; i >= 0; i--) {
        if (binaryString[i] === "1") {
          completedSteps.push(binaryString.length - i - 5 + 1);
        } else {
          incompleteSteps.push(binaryString.length - i - 5 + 1);
        }
      }
      return {
        currentStep: currentStep,
        completedSteps: completedSteps,
        incompleteSteps: incompleteSteps,
      };
    },
    async getDomainRecord() {
      const result = await getDomainRecord();
      if (result.code == 200) {
        const domainInfos = result.data.data;
        const tableData_1 = domainInfos.filter(
          (item) => item.registerStep == 3
        )[0];
        this.tableData_1[0].content_4 = tableData_1.info;
        const handler_tableData_2 = domainInfos.filter(
          (item) => item.registerStep == 6
        );
        handler_tableData_2.map((data, index) => {
          this.tableData_2[index].content_4.text = data.info;
          if (index == 2) {
            this.tableData_2[index].content_1.text = data.hostName;
          }
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    setcellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          "word-break": "break-word",
          "background-color": "#F5F5F5",
          color: "#858585",
          "font-size": "14px",
        };
      }
      return { "word-break": "break-word" };
    },
    startCopy(text) {
      this.$copyText(text).then(
        (res) => {
          this.$message({
            customClass: "meetingMessage",
            dangerouslyUseHTMLString: true,
            message:
              '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
              '<div class="copySucceeded"></div>' +
              "<span>" +
              this.$t("copySucceeded") +
              "</span>" +
              "</div>",
            duration: 1000,
          });
        },
        (err) => {
          this.$message({
            type: "error",
            message: this.$t("copyFailed"),
          });
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.domain_management
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar
    width: 6px;
  &::-webkit-scrollbar-thumb
    border-radius: 3px;
    box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
    background: #D9D9D9;
  &::-webkit-scrollbar-track
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 0;
    background: rgba(0, 0, 0, 0);
  .header
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #D9D9D9;
    .header_button
      width: 120px;
      height: 28px;
      flex-shrink: 0;
      border-right: 1px solid #D9D9D9;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        font-size: 20px;
        color: #858585;
        cursor: pointer;
    .header_title
      flex: 1;
      min-width: 0;
      height: 28px;
      color: #292D32;
      line-height: 28px;
      padding: 0 0 0 30px;
      font-size: 20px;
    .right_button
      width: 192px;
      height: 40px;
      border-radius: 4px;
      background: #52BD68;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #FFFFFF;
      margin-right: 40px;
      user-select: none;
      cursor: pointer;
  .title
    width: 100%;
    margin-top: 30px;
    padding: 0 50px;
    color: #292D32;
    font-size: 16px;
  .contentTable_1
    width: 806px;
    padding: 0 0 0 50px;
    margin: 24px 0 18px 0;
  .contentTable_2
    width: 1004px;
    padding: 0 0 0 50px;
    margin: 24px 0 18px 0;
</style>
