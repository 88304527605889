var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "selectDialog",
      attrs: {
        visible: _vm.showSelectDialog,
        width: "500px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "before-close": _vm.closeSelectDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showSelectDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.sendLoading,
              expression: "sendLoading"
            }
          ],
          staticClass: "dialogBox",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.membersLoading,
                  expression: "membersLoading"
                }
              ],
              staticClass: "dialogBody",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "#FFFFFF"
              }
            },
            [
              _c("div", { staticClass: "dialogIcon" }, [
                _c("span", { staticClass: "iconfont_Me icon-send iconStyle" })
              ]),
              _c("div", { staticClass: "dialogSearch" }, [
                _c(
                  "div",
                  { staticClass: "searchBox" },
                  [
                    _c("el-input", {
                      attrs: {
                        resize: "none",
                        maxlength: "200",
                        placeholder: _vm.placeholderIcon
                      },
                      on: { input: _vm.searchChange },
                      model: {
                        value: _vm.searchText,
                        callback: function($$v) {
                          _vm.searchText = $$v
                        },
                        expression: "searchText"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "selectAll" },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("selectAll")))
                  ]),
                  _c("el-checkbox", {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "selectList" }, [
                _c(
                  "div",
                  { staticClass: "scroll" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.handleCheckedMembersChange },
                        model: {
                          value: _vm.checkedMembers,
                          callback: function($$v) {
                            _vm.checkedMembers = $$v
                          },
                          expression: "checkedMembers"
                        }
                      },
                      _vm._l(_vm.membersList, function(member) {
                        return _c(
                          "div",
                          { key: member.providerId, staticClass: "selectItem" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "itemCheckbox",
                                attrs: {
                                  label: member.providerId,
                                  disabled: member.freezeStatus
                                }
                              },
                              [
                                _c("div", { staticClass: "itemBody" }, [
                                  _c(
                                    "div",
                                    { staticClass: "itemAvatar" },
                                    [
                                      _c("Avatar", {
                                        attrs: {
                                          path: member.profilePhoto,
                                          international:
                                            member.country == ""
                                              ? 0
                                              : _vm.toNumber(member.country),
                                          isFrozen: member.freezeStatus
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "itemContent" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(member.name) +
                                          "\n                    "
                                      )
                                    ]),
                                    _c("div", { staticClass: "id" }, [
                                      _vm._v("ID:  " + _vm._s(member.ucId))
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]),
              _vm.checkedMembers.length !== 0
                ? _c("div", { staticClass: "selectedList" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.avatarListWidth > 480,
                            expression: "avatarListWidth > 480"
                          }
                        ],
                        staticClass: "direction direction_left",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changePosition("left")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-left iconStyle"
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.avatarListWidth > 480,
                            expression: "avatarListWidth > 480"
                          }
                        ],
                        staticClass: "direction direction_right",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.changePosition("right")
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-right iconStyle"
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "avatarList",
                        style: {
                          transform: `translate3d(${_vm.avatarListPosition}px,0,0)`,
                          width: `${_vm.avatarListWidth}px`
                        }
                      },
                      _vm._l(_vm.selectedList, function(member) {
                        return _c(
                          "div",
                          { key: member.providerId, staticClass: "avatar" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "close",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteChecked(member.providerId)
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont_Me icon-x iconStyle"
                                })
                              ]
                            ),
                            _c("Avatar", {
                              attrs: {
                                path: member.profilePhoto,
                                international:
                                  member.country == ""
                                    ? 0
                                    : _vm.toNumber(member.country)
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "confirmButton",
              class: { commomButtonHover: _vm.checkedMembers.length !== 0 },
              style: {
                cursor:
                  _vm.checkedMembers.length !== 0 ? "pointer" : "not-allowed"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.send.apply(null, arguments)
                }
              }
            },
            [
              _c("span", { staticClass: "iconfont_Me icon-check iconStyle" }),
              _vm.checkedMembers.length !== 0
                ? _c("span", { staticClass: "countStyle" }, [
                    _vm._v("(" + _vm._s(_vm.checkedMembers.length) + ")")
                  ])
                : _vm._e()
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }