var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "changeTeamNameDialog",
      attrs: {
        visible: _vm.showChangeTeamNameDialog,
        width: "404px",
        "close-on-click-modal": false,
        "before-close": _vm.closeChangeTeamNameDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showChangeTeamNameDialog = $event
        }
      }
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("teamName")) + "\n  ")
      ]),
      _c("div", { staticClass: "dialogBody" }, [
        _c(
          "div",
          { staticClass: "inputBox" },
          [
            _c("el-input", {
              attrs: {
                resize: "none",
                maxlength: "200",
                placeholder: _vm.placeholderIcon
              },
              model: {
                value: _vm.groupName,
                callback: function($$v) {
                  _vm.groupName = $$v
                },
                expression: "groupName"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "buttonBox" }, [
          _c(
            "div",
            {
              staticClass: "buttonStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.closeChangeTeamNameDialog.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
          ),
          _c(
            "div",
            {
              staticClass: "buttonStyle",
              class: _vm.groupName != "" ? "commomButtonHover" : "",
              style: {
                "margin-left": "10px",
                cursor: _vm.groupName != "" ? "pointer" : "not-allowed"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.change.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }