import { render, staticRenderFns } from "./notificationForMember.vue?vue&type=template&id=1ae64072&scoped=true"
import script from "./notificationForMember.vue?vue&type=script&lang=js"
export * from "./notificationForMember.vue?vue&type=script&lang=js"
import style0 from "./notificationForMember.vue?vue&type=style&index=0&id=1ae64072&lang=stylus&scoped=true"
import style1 from "./notificationForMember.vue?vue&type=style&index=1&id=1ae64072&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ae64072",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\new_front_melinked\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ae64072')) {
      api.createRecord('1ae64072', component.options)
    } else {
      api.reload('1ae64072', component.options)
    }
    module.hot.accept("./notificationForMember.vue?vue&type=template&id=1ae64072&scoped=true", function () {
      api.rerender('1ae64072', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/newVersion/pageViews/teamManagement/dialog/notificationForMember.vue"
export default component.exports