var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "domain_management" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header_button" }, [
        _c("i", {
          staticClass: "iconfont_Me icon-return iconStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("closeDomainManagement")
            }
          }
        })
      ]),
      _c("div", { staticClass: "header_title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("domainManagement")) +
            "——" +
            _vm._s(_vm.teamDomainInfo.mailDomain) +
            "\n    "
        )
      ]),
      _c(
        "div",
        {
          directives: [{ name: "waves", rawName: "v-waves" }],
          staticClass: "right_button",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.startMigrate.apply(null, arguments)
            }
          }
        },
        [_vm._v("\n      " + _vm._s(_vm.$t("mailboxMigrate_1")) + "\n    ")]
      )
    ]),
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("ownershipOfTheDomain")) + "\n  ")
    ]),
    _c(
      "div",
      { staticClass: "contentTable_1" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData_1,
              border: "",
              "header-cell-style": {
                background: "#F5F5F5",
                "font-weight": "400",
                color: "#858585",
                "font-size": "14px"
              },
              "row-style": {
                color: "#000000",
                "font-size": "14px"
              },
              "cell-style": {
                "word-break": "break-word"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "content_1",
                label: _vm.$t("tableHeader_1"),
                width: "180",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_2",
                label: _vm.$t("tableHeader_2"),
                width: "90",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_3",
                label: _vm.$t("tableHeader_3"),
                width: "90",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_4",
                label: _vm.$t("tableHeader_4"),
                align: "center",
                resizable: false
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          style: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("div", { style: { "word-break": "break-word" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.content_4) +
                                "\n            "
                            )
                          ]),
                          _c("i", {
                            staticClass: "el-icon-document-copy",
                            style: {
                              color: "#52bd68",
                              "font-size": "18px",
                              "margin-left": "6px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.startCopy(row.content_4)
                              }
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("DNSConfiguration")) + "\n  ")
    ]),
    _c(
      "div",
      { staticClass: "contentTable_2" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData_2,
              border: "",
              "header-cell-style": {
                background: "#F5F5F5",
                "font-weight": "400",
                color: "#858585",
                "font-size": "14px"
              },
              "row-style": {
                color: "#000000",
                "font-size": "14px"
              },
              "cell-style": _vm.setcellStyle
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "column_title",
                label: "",
                width: "180",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_1",
                label: _vm.$t("tableHeader_1"),
                width: "180",
                align: "center",
                resizable: false
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          style: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("div", { style: { "word-break": "break-word" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.content_1.text || row.content_1) +
                                "\n            "
                            )
                          ]),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.content_1.copy,
                                expression: "row.content_1.copy"
                              }
                            ],
                            staticClass: "el-icon-document-copy",
                            style: {
                              color: "#52bd68",
                              "font-size": "18px",
                              "margin-left": "6px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.startCopy(row.content_1.text)
                              }
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_2",
                label: _vm.$t("tableHeader_2"),
                width: "90",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_3",
                label: _vm.$t("tableHeader_3"),
                width: "90",
                align: "center",
                resizable: false
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_4",
                label: _vm.$t("tableHeader_4"),
                align: "center",
                resizable: false
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function({ row }) {
                    return [
                      _c(
                        "div",
                        {
                          style: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          }
                        },
                        [
                          _c("div", { style: { "word-break": "break-word" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(row.content_4.text) +
                                "\n            "
                            )
                          ]),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.content_4.copy,
                                expression: "row.content_4.copy"
                              }
                            ],
                            staticClass: "el-icon-document-copy",
                            style: {
                              color: "#52bd68",
                              "font-size": "18px",
                              "margin-left": "6px",
                              cursor: "pointer"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.startCopy(row.content_4.text)
                              }
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "content_5",
                label: _vm.$t("tableHeader_5"),
                width: "90",
                align: "center",
                resizable: false
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }