var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "narrowBox teamManagement" },
    [
      _vm.domainManagement
        ? [
            _c(
              "div",
              { staticClass: "domainManagementBlock" },
              [
                _c("DomainManagement", {
                  on: {
                    closeDomainManagement: function($event) {
                      _vm.domainManagement = false
                    }
                  }
                })
              ],
              1
            )
          ]
        : [
            _c(
              "el-tabs",
              {
                staticClass: "settings-tabs",
                attrs: { "tab-position": _vm.tabPosition },
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              },
              [
                _c("el-tab-pane", { attrs: { name: "myTeam" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "tabTitle",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [
                      _c("div", { staticClass: "iconBox" }, [
                        _c("span", { staticClass: "iconfont_Me icon-a-zu9560" })
                      ]),
                      _c("span", { staticClass: "settingTitle" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("myTeam")) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "managementPage" },
                    [
                      _c("ManagementList", {
                        attrs: { activeName: _vm.active },
                        on: { openDomainManagement: _vm.openDomainManagement }
                      })
                    ],
                    1
                  )
                ]),
                _c("el-tab-pane", { attrs: { name: "notification" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "tabTitle",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [
                      _c("div", { staticClass: "iconBox" }, [
                        _c("img", {
                          style: { width: "24px", height: "24px" },
                          attrs: {
                            src: require("../../../assets/images/teamManagement/manageNoti.png")
                          }
                        })
                      ]),
                      _c("span", { staticClass: "settingTitle" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("manageNotifications")) +
                            "\n          "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "managementPage" },
                    [
                      _c("TeamNotification", {
                        attrs: { activeName: _vm.active }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }