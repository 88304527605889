<template>
  <el-dialog
    :visible.sync="showMemberRecordDialog"
    width="540px"
    class="memberInfoDialog"
    :close-on-click-modal="false"
    :before-close="closeMemberRecordDialog"
    :destroy-on-close="true"
    :top="'0'"
  >
    <div class="dialogHeader">
      <div class="avatarBox" v-if="selectedRow && selectedRow.profilePhoto">
        <Avatar
          :path="selectedRow.profilePhoto"
          :international="
            selectedRow.country == '' ? 0 : toNumber(selectedRow.country)
          "
          :isFrozen="selectedRow.freezeStatus"
          :isOwnerOfTheTeam="selectedRow.identity == 4 ? true : false"
        >
        </Avatar>
      </div>
      <div class="memberInfo" v-if="selectedRow">
        <div class="memberName" :title="selectedRow.name">
          {{ selectedRow.name }}
        </div>
        <div class="memberId commonStyle" :title="selectedRow.ucId">
          <img
            :src="
              require('../../../../assets/images/teamManagement/melinkedID_smart.png')
            "
          />
          {{ selectedRow.ucId }}
        </div>
        <template v-if="selectedRow.emails.length > 0">
          <div
            class="memberEmail commonStyle"
            v-for="item in reorderArrayByCondition(selectedRow.emails, 3)"
            :title="item.mailAddress"
          >
            <img
              :src="
                require('../../../../assets/images/teamManagement/email_smart.png')
              "
            />
            {{ item.mailAddress }}
          </div>
        </template>
      </div>
      <div class="placeholder"></div>
    </div>
    <div
      class="dialogBody"
      v-loading="recordLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div class="item" v-for="item in listData" :key="item.recordTime">
        <span
          class="span_l"
          :style="{ color: '#292D32', 'font-size': '16px' }"
          :title="itemTitle(item)"
        >
          {{ itemTitle(item) }}
        </span>
        <span
          class="span_r"
          :style="{ color: '#858585', 'font-size': '16px' }"
          >{{ item.recordTime }}</span
        >
      </div>
      <div class="placeholderMap" v-if="listData.length == 0">
        <PlaceholderMap :placeholderType="2"></PlaceholderMap>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { findMemberRecoed } from "@/api/newVersion/team";
import PlaceholderMap from "../../../components/placeholderMap/index.vue";
import Avatar from "../../../components/avatarDisplay/index.vue";
export default {
  name: "MemberRecordDialog",
  components: {
    PlaceholderMap,
    Avatar,
  },
  props: {
    showMemberRecordDialog: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      listData: [],
      recordLoading: false,
    };
  },
  watch: {
    "selectedRow.providerId": {
      async handler(val, old) {
        if (val && val != old) {
          this.recordLoading = true;
          const result = await findMemberRecoed(val);
          this.recordLoading = false;
          if (result.code) {
            this.listData = result.data.data;
          } else {
            this.$message({
              type: "error",
              message: result.message || result.data.message,
              onClose: () => {
                this.closeMemberRecordDialog();
              },
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    reorderArrayByCondition(array, condition) {
      // 找到所有符合条件的元素
      let matchingElements = array.filter((element) => {
        return element.bindCondition === condition;
      });
      // 如果没有符合条件的元素，直接返回原数组
      if (matchingElements.length === 0) {
        return array;
      }
      // 将符合条件的元素移动到数组的第一个位置
      let newArray = matchingElements.concat(
        array.filter((element) => {
          return element.bindCondition !== condition;
        })
      );
      return newArray;
    },
    // 字符串转数字
    toNumber(str) {
      return parseInt(str);
    },
    itemTitle(item) {
      let title = "";
      switch (item.recordStatus) {
        case 0:
          title = this.$t("joiningTime");
          break;
        case 1:
          title = this.$t("freezingTime");
          break;
        case 2:
          title = this.$t("DefreezeTime");
          break;
        case 3:
          title = `${this.$t("bindMailbox")} <${item.email}>`;
          break;
        case 4:
          title = this.$t("unbindEmail");
          break;
      }
      return title;
    },
    closeMemberRecordDialog() {
      this.listData = [];
      this.$emit("closeMemberRecordDialog");
    },
  },
};
</script>
<style lang="stylus" scoped>
.memberInfoDialog
  .dialogHeader
    width: 500px;
    height: 100px;
    flex-shrink: 0;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .avatarBox
      width: 50px;
      height: 50px;
      flex-shrink: 0;
    .placeholder
      width: 30px;
      height: 50px;
      flex-shrink: 0;
    .memberInfo
      flex: 1;
      min-width: 0;
      height: 100%;
      padding:0 36px 0 10px;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar
        width: 10px;
      &::-webkit-scrollbar-thumb
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
        background: rgba(100, 100, 100, 0.1);
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.05);
      .memberName
        width: 100%;
        font-size: 16px;
        color: #292D32;
        margin-bottom: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 20px;
      .commonStyle
        width: 100%;
        font-size: 16px;
        color: #858585;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        img
          width: 16px;
          height: 16px;
          vertical-align: middle;
          margin-right: 6px;
      .memberId
        margin-bottom: 6px;
  .dialogBody
    width: 100%
    min-height: 0;
    flex: 1;
    box-sizing: border-box;
    overflow-x hidden;
    overflow-y auto;
    padding-top: 5px;
    &::-webkit-scrollbar
      width: 2px;
    &::-webkit-scrollbar-thumb
      border-radius: 1px;
      box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
      background: rgba(100, 100, 100, 0.2);
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    .item
      padding 15px 20px;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: background 0.3s;
      &:hover
        background: #F5F5F5;
      .span_l
        flex: 1;
        display: inline-block;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      .span_r
        flex-shrink: 0
        display: inline-block;
        height: 100%;
    .placeholderMap
      width: 100%;
      height: 100%;
</style>
<style lang="stylus">
.memberInfoDialog
  .el-dialog
    margin-top 0 !important;
    padding 30px 0 0 0 !important;
    height: 480px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      width: 100%;
      flex 1;
      overflow hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0;
</style>
