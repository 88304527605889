<template>
  <div class="membersBox teamAccount_membersList">
    <el-table
      :data="members"
      style="width: 100%"
      :height="'100%'"
      @row-click="openMemberInfoDialog"
    >
      <el-table-column :label="$t('userName')" width="200">
        <template slot-scope="scope">
          <div class="userInfo">
            <div class="userPhoto">
              <Avatar
                :path="scope.row.profilePhoto"
                :international="
                  scope.row.country == '' ? 0 : toNumber(scope.row.country)
                "
                :isFrozen="scope.row.freezeStatus"
                :isOwnerOfTheTeam="scope.row.identity == 4 ? true : false"
              >
              </Avatar>
            </div>
            <div class="userIdBox">
              <div
                class="name userStyle"
                :class="scope.row.vipStatus ? 'xvipName' : ''"
                style="display:flex;align-items:center"
                :title="scope.row.name"
              >
                {{ scope.row.name }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('paidMember')"
                  placement="bottom"
                  popper-class="me-tooltip-effect"
                >
                  <VipIcon
                    style="margin-left:2px"
                    :vipStatus="scope.row.vipStatus"
                    :iconWidth="18"
                    :id="scope.row.id"
                  ></VipIcon>
                </el-tooltip>
              </div>
              <div
                class="id userStyle"
                :style="{ color: scope.row.updatePassword ? '' : '#183CF3' }"
                :title="scope.row.ucId"
              >
                ID:&nbsp;{{ scope.row.ucId }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('role')" width="70">
        <template slot-scope="scope">
          <div>{{ transformRoles(scope.row.identity) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="emails" :label="$t('emailAddress')" width="250">
        <template slot-scope="{ row }">
          <span
            class="emailText"
            v-if="row.emails.length > 0"
            :title="filterBindCondition(row)"
            v-html="filterBindCondition(row)"
          ></span>
        </template>
      </el-table-column>
      <el-table-column prop="joinTime" :label="$t('joiningTime')" width="160">
      </el-table-column>
      <el-table-column :label="$t('operation')" width="150">
        <template slot-scope="{ row }">
          <div class="operating">
            <el-tooltip
              effect="dark"
              :content="row.freezeStatus ? $t('unfreeze') : $t('frozen')"
              placement="bottom"
            >
              <img
                :src="transformFreezeStatus(row.freezeStatus)"
                :style="{ width: '24px', height: '24px', 'margin-left': '0px' }"
                @click.stop="changeFreezeStatus(row)"
                v-if="row.identity != 4"
                class="buttonStyle"
              />
            </el-tooltip>
            <el-tooltip
              effect="dark"
              :content="$t('record')"
              placement="bottom"
            >
              <img
                :src="
                  require('../../../assets/images/teamManagement/records.png')
                "
                :style="{ width: '24px', height: '24px' }"
                @click.stop="openRecordDialog(row)"
                v-if="row.identity != 4"
                class="buttonStyle"
              />
            </el-tooltip>
            <el-tooltip
              effect="dark"
              :content="$t('unbindEmail')"
              placement="bottom"
            >
              <img
                :src="
                  require('../../../assets/images/teamManagement/unbindEmail.png')
                "
                :style="{ width: '24px', height: '24px' }"
                @click.stop="unbindEmail(row)"
                v-if="
                  row.identity != 4 &&
                    row.emails.length != 0 &&
                    checkBindCondition(row.emails)
                "
                class="buttonStyle"
              />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="empty" v-if="members.length === 0">
      <PlaceholderMap :placeholderType="1"></PlaceholderMap>
    </div>
  </div>
</template>

<script>
import Avatar from "../../components/avatarDisplay/index.vue";
import PlaceholderMap from "../../components/placeholderMap/index.vue";
import VipIcon from "@/newVersion/components/icons/IconVip.vue";
export default {
  name: "MembersTable",
  components: {
    Avatar,
    PlaceholderMap,
    VipIcon,
  },
  props: {
    members: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mailDomain: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    checkBindCondition(emails) {
      return (
        emails.findIndex((email) => {
          return email.bindCondition == 3;
        }) != -1
      );
    },
    toNumber(str) {
      return parseInt(str);
    },
    filterBindCondition(row) {
      const emails = row.emails.filter(
        (email) => email.mailAddress.indexOf(this.mailDomain) != -1
      );
      let emailStr = "";
      if (emails.length > 0) {
        emails.map((email) => {
          emailStr += email.mailAddress + ",";
        });
      }
      emailStr = emailStr.slice(0, emailStr.length - 1);
      emailStr = emailStr.replace(/,/g, "<br>");
      return emailStr;
    },
    // 解绑邮箱
    async unbindEmail(row) {
      this.$emit("unbindEmail", row);
    },
    transformRoles(identity) {
      let role = "";
      switch (identity) {
        case 4:
          role = this.$t("administrator");
          break;
        default:
          role = this.$t("member");
          break;
      }
      return role;
    },
    transformFreezeStatus(freezeStatus) {
      let role = require("../../../assets/images/teamManagement/frozen.png");
      if (freezeStatus) {
        role = require("../../../assets/images/teamManagement/unfreeze.png");
      }
      return role;
    },
    changeFreezeStatus(row) {
      this.$emit("changeFreezeStatus", row);
    },
    openRecordDialog(row) {
      this.$emit("openMemberRecordDialog", row);
    },
    openMemberInfoDialog(row) {
      const hasNoPasswordUpdate = !row.updatePassword;
      const hasNoEmails = row.emails.length === 0;
      if (hasNoPasswordUpdate) {
        if (hasNoEmails) {
          this.$emit("openMemberInfoDialog", row);
          return;
        }

        // 判断是否是邮箱是否有团队域名
        const isMailInDomain =
          row.emails.findIndex((email) =>
            email.mailAddress.includes(this.mailDomain)
          ) != -1;
        // 判断是否是账号原生邮箱
        const isBindConditionValid =
          row.emails.findIndex((email) => email.bindCondition == 1) != -1;
        if (isMailInDomain && isBindConditionValid) {
          this.$emit("openCreatedEmailOrIDSuccessDialog", row);
        } else {
          this.$emit("openMemberInfoDialog", row);
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.membersBox
  width: 100%;
  overflow hidden;
  box-sizing: border-box;
  padding 10px 18px 0 18px;
  flex 1;
  height: 100%;
  background: #FFFFFF;
  overflow: hidden;
  .empty
    height: calc(100% - 47px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  .userInfo
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .userPhoto
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      overflow hidden;
    .userIdBox
      flex: 1;
      height: 40px;
      box-sizing: border-box;
      padding-left: 17px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .userStyle
        width: 100%;
        font-weight: 400;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      .name
        font-size: 14px;
        font-size: #333333;
        line-height: 22px;
      .id
        font-size: 12px;
        line-height: 18px;
  .emailText
    word-break: keep-all;
  .operating
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .buttonStyle
      color #200E32;
      font-size: 14px;
      margin-left: 24px;
    .iconStyle
      color #52BD68;
      font-size: 22px;
      margin-left: 20px;
      cursor pointer;
</style>
<style lang="stylus">
.teamAccount_membersList
  .el-table
    .el-table__row
      cursor pointer;
    .el-table__empty-block
      display: none;
    .el-table__header-wrapper
      .el-table__header
        .has-gutter
          .el-table__cell
            border: none
            .cell
              font-size: 14px !important;
              font-weight: 400 !important;
              text-align: left!important;
              color: #200e32!important;
    .el-table__body-wrapper
      padding-bottom: 10px;
      &::-webkit-scrollbar
        width: 6px;
      &::-webkit-scrollbar-thumb
        border-radius: 3px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
        background: #D9D9D9;
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
        border-radius: 0;
        background: rgba(0, 0, 0, 0);
</style>
