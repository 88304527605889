<template>
  <div
    class="notificationTable"
    v-loading="notificationsLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <el-table :data="notifications" style="width: 100%" @row-click="rowClick">
      <el-table-column :label="$t('noticeContent')" width="320">
        <template slot-scope="scope">
          <div class="contentBox">
            {{ getItemText(scope.row) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('timeOfPublication')" width="180">
        <template slot-scope="scope">
          <div class="contentBox">
            {{ scope.row.createTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="joinTime"
        :label="$t('statusOfThePublication')"
        width="180"
      >
        <template slot-scope="scope">
          <span class="iconfont_Me icon-choice-fill iconStyle"></span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('operation')">
        <template slot-scope="scope">
          <span
            @click.stop="deleteNotification(scope.row.messageId)"
            class="iconfont_Me icon-delet operatingIconStyle"
          ></span>
        </template>
      </el-table-column>
    </el-table>
    <div class="empty" v-if="notifications.length === 0">
      <PlaceholderMap :placeholderType="1"></PlaceholderMap>
    </div>
  </div>
</template>
<script>
import PlaceholderMap from "../../components/placeholderMap/index.vue";
import { deleteNotification } from "../../../api/newVersion/team/index";
export default {
  name: "NotificationTable",
  components: {
    PlaceholderMap,
  },
  props: {
    notifications: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      notificationsLoading: false,
    };
  },
  methods: {
    rowClick(row) {
      this.$store.commit("setNotificationDialogId", row.messageId);
    },
    async deleteNotification(messageId) {
      this.$confirm("", "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        iconClass: "iconfont_Me icon-Iconly-Light-Delete-copy",
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
      })
        .then(async () => {
          const result = await deleteNotification(messageId);
          if (result.code == 200) {
            this.$emit("getNotificationList");
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch((e) => {});
    },
    getItemText(notification) {
      if (notification.title !== "") {
        return notification.title;
      }
      let text = notification.title;
      try {
        const detail = JSON.parse(notification.message.detail);
        const textDetails = detail.filter((item) => item.key == "text");
        if (textDetails.length !== 0) {
          text = textDetails[0].content;
        } else {
          const webDetails = detail.filter((item) => item.key == "url");
          if (webDetails.length !== 0) {
            const webContent = JSON.parse(webDetails[0].content);
            text = webContent.showLink;
          }
        }
      } catch (error) {
        if (notification.message.tableText) {
          text = notification.message.tableText;
        }
      }
      return text;
    },
  },
};
</script>
<style lang="stylus" scoped>
.notificationTable
  width: 100%;
  overflow hidden;
  box-sizing: border-box;
  padding 0 40px;
  flex 1;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar
    width: 4px;
  &::-webkit-scrollbar-thumb
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
    background: rgba(100, 100, 100, 0.2);
  &::-webkit-scrollbar-track
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  .empty
    height: calc(100% - 47px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  .contentBox
    color #808080;
    font-size: 14px;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  .iconStyle
    font-size: 22px;
    color #33CC66;
  .operatingIconStyle
    font-size: 20px;
    color #808080;
    cursor pointer;
</style>
<style lang="stylus">
.notificationTable
  .el-table
    .el-table__row
      cursor pointer;
    .el-table__header-wrapper
      .el-table__header
        .has-gutter
          .el-table__cell
            border: none;
            .cell
              font-size: 14px !important;
              font-weight: 400 !important;
              text-align: left!important;
              color: #200e32!important;
    .el-table__empty-block
      display: none;
    .el-table__body-wrapper
      .el-table__cell
        padding 18px 0 !important;
</style>
