<template>
  <el-dialog
    :visible.sync="createEmailForMemberDialog"
    width="640px"
    class="createEmailForMemberDialog"
    :close-on-click-modal="false"
    :before-close="closeCreateEmailForMemberDialog"
    :destroy-on-close="true"
  >
    <div class="dialogIcon">
      <div class="icon">
        <span class="iconfont_Me icon-user-fill iconStyle"></span>
      </div>
    </div>
    <div
      class="dialogBody"
      v-loading="dialogLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255,255,255,0.3)"
    >
      <div class="contentBox">
        <div class="infoBox">Melinked ID: {{ melinkedID }}</div>
        <div class="inputBox">
          <div class="inputOutter">
            <CommonInput
              @inputChange="getEmailText"
              :mailbox="handlerDomain"
              :needInputshowError="emailInputshowError"
              :checkAccountSuccess="checkAccountSuccess"
              :maxlength="inputLength"
              :noChinese="true"
            >
              <el-image
                :style="{
                  width: '28px',
                  height: '28px',
                }"
                :src="
                  require('../../../../assets/images/email/loginDialog/email_not_selected.png')
                "
                :fit="'cover'"
              ></el-image>
            </CommonInput>
          </div>
        </div>
        <div class="failedText" v-if="emailInputshowError">
          {{ teamEmailInputError }}
        </div>
      </div>
      <div
        class="confirmButton"
        :class="checkSuccess ? 'commomButtonHover' : ''"
        @click.stop="confirm"
        :style="{
          cursor: checkSuccess ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CommonInput from "../../../components/inputComponent/commonInput.vue";
import { bindNewEmailForMember } from "@/api/newVersion/team/index";
import { checkEmailAvailable } from "@/api/newVersion/melinked_email";
export default {
  name: "CreateEmailForMemberDialog",
  components: {
    CommonInput,
  },
  props: {
    createEmailForMemberDialog: {
      type: Boolean,
      default: false,
    },
    memberInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    melinkedID() {
      let melinkedID = "";
      if (this.memberInfo) {
        melinkedID = this.memberInfo.ucId;
      }
      return melinkedID;
    },
    checkSuccess() {
      return (
        this.emailText.length > 0 &&
        this.checkAccountSuccess &&
        !this.emailInputshowError
      );
    },
    teamDomainInfo() {
      return this.$store.state.team.teamDomainInfo;
    },
    handlerDomain() {
      let handlerDomain = "";
      if (this.teamDomainInfo) {
        handlerDomain = `@${this.teamDomainInfo.mailDomain}`;
      }
      return handlerDomain;
    },
    inputLength() {
      return 100 - this.handlerDomain.length;
    },
  },
  data() {
    return {
      emailInputshowError: false,
      checkAccountSuccess: false,
      emailText: "",
      dialogLoading: false,
      teamEmailInputError: "",
      timer: null,
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    getEmailText(text) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (text !== "") {
        let regex = /^[A-Za-z0-9_.]+$/g;
        const flag = regex.test(text);
        if (!flag) {
          this.teamEmailInputError = this.$t("teamEmailInputError_2");
          this.checkAccountSuccess = false;
          this.emailInputshowError = true;
          return;
        }
        this.timer = setTimeout(async () => {
          let result = await checkEmailAvailable(
            `${text}${this.handlerDomain}`
          );
          if (result.code == 200 && !result.data.data) {
            this.emailText = text;
            this.checkAccountSuccess = true;
            this.$forceUpdate();
          } else if (result.data.data) {
            this.teamEmailInputError = this.$t("teamEmailInputError_1");
            this.checkAccountSuccess = false;
          }
          this.emailInputshowError = !this.checkAccountSuccess;
        }, 500);
      } else {
        this.emailText = "";
        this.emailInputshowError = false;
        this.checkAccountSuccess = false;
      }
    },
    closeCreateEmailForMemberDialog() {
      this.$emit("closeCreateEmailForMemberDialog");
    },
    async confirm() {
      if (this.checkSuccess) {
        this.dialogLoading = true;
        const result = await bindNewEmailForMember({
          memberId: this.memberInfo.providerId,
          email: `${this.emailText.toLowerCase()}${this.handlerDomain}`,
        });
        this.dialogLoading = false;
        if (result.code == 200) {
          this.memberInfo.emails = result.data.data.emails;
          this.$emit("confirm", this.memberInfo);
          this.$nextTick(() => {
            this.closeCreateEmailForMemberDialog();
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.createEmailForMemberDialog
  .dialogIcon
    height: 68px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    .icon
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background: #33CC66;
      display flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        color: white;
        font-size: 54px;
  .dialogBody
    width: 100%;
    flex 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    .contentBox
      width: 100%;
      flex: 1;
      min-height: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      .infoBox
        margin: 35px 0 6px 0;
        width: 100%;
        line-height: 22px;
        color: #858585;
        font-size: 18px;
        text-align: left;
      .inputBox
        width: 100%;
        min-height: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .inputOutter
          width: 100%;
          height: 48px;
      .failedText
        width: 100%;
        min-height: 22px;
        line-height: 22px;
        text-align: left;
        font-size: 16px;
        color: #FF0000;
        margin-top: 12px;
    .confirmButton
      width: 100%;
      height: 46px;
      border-radius: 4px;
      background: rgba(0,0,0,0.10);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      .iconStyle
        font-size: 24px;
        color: #FFFFFF;
</style>
<style lang="stylus">
.createEmailForMemberDialog
  .el-dialog
    margin-top 0 !important;
    padding 40px 80px 50px 80px !important;
    height: 460px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      padding 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
</style>
