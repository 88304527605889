var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "notificationForMemberDialog",
      attrs: {
        visible: _vm.notificationForMemberDialog,
        width: "640px",
        "close-on-click-modal": false,
        "before-close": _vm.closeNotificationForMemberDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.notificationForMemberDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogIcon" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("noticeContent")) + "\n  ")
      ]),
      _c("div", { staticClass: "dialogBody" }, [
        _c(
          "div",
          {
            ref: "contentBox",
            staticClass: "contentBox",
            style: {
              width: "100%",
              flex: "1",
              "min-height": "0",
              display: "flex",
              "align-items": "center",
              "flex-direction": "column",
              "justify-content": "flex-start"
            }
          },
          [
            _c(
              "div",
              {
                style: {
                  "font-size": "18px",
                  color: "#292D32",
                  "line-height": "25px",
                  "text-align": "left"
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("teamMemberNotification", { n: _vm.melinkedID })
                    ) +
                    "\n      "
                )
              ]
            ),
            _c(
              "div",
              {
                style: {
                  width: "100%",
                  "margin-top": "28px",
                  "font-size": "18px",
                  color: "#292D32",
                  "line-height": "25px",
                  "text-align": "left",
                  "font-weight": "bold"
                }
              },
              [
                _c(
                  "div",
                  {
                    style: {
                      "margin-bottom": "6px"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("emailAddress")) +
                        ":" +
                        _vm._s(_vm.emailAddress) +
                        "\n        "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    style: {
                      "margin-bottom": "6px"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("emailPassword")) +
                        ":" +
                        _vm._s(_vm.emailPassword) +
                        "\n        "
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _c("div", { staticClass: "buttonBox" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "buttonStyle",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.sendNotification.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "iconfont_Me icon-send iconStyle" })]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }