var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teamNotification" }, [
    _c("div", { staticClass: "notificationHeader" }),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.notificationLoading,
            expression: "notificationLoading"
          }
        ],
        staticClass: "notificationBox",
        attrs: {
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "#FFFFFF"
        }
      },
      [
        _c("div", { staticClass: "searchAndAddBox" }, [
          _c(
            "div",
            { staticClass: "searchBox" },
            [
              _c("el-input", {
                attrs: {
                  resize: "none",
                  maxlength: "200",
                  placeholder: _vm.placeholderIcon
                },
                on: { input: _vm.searchChange },
                model: {
                  value: _vm.searchText,
                  callback: function($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "sendBox",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.send.apply(null, arguments)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../assets/images/teamManagement/sendNotification.png")
                }
              })
            ]
          )
        ]),
        _c("NotificationTable", {
          attrs: { notifications: _vm.notificationList },
          on: { getNotificationList: _vm.getNotificationList }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "notificationPagination" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "prev, pager, next",
            "page-size": _vm.pageSize,
            total: _vm.dataTotal
          },
          on: { "current-change": _vm.currentChange }
        })
      ],
      1
    ),
    _vm.notificationEditor
      ? _c(
          "div",
          { staticClass: "notificationEditor" },
          [_c("NotificationEditor", { on: { closeEditor: _vm.closeEditor } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }