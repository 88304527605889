<template>
  <el-dialog
    :visible.sync="createMemberMethodDialog"
    width="640px"
    class="createMemberMethodDialog"
    :close-on-click-modal="false"
    :before-close="closeCreateMemberMethodDialog"
    :destroy-on-close="true"
  >
    <div class="dialogIcon">
      <div class="icon">
        <span class="iconfont_Me icon-user-fill iconStyle"></span>
      </div>
    </div>
    <div class="dialogBody">
      <div class="contentBox">
        <div class="selectBox">
          <div
            class="selectItem"
            v-for="item in selectItems"
            :key="item.value"
            @click.stop="selectThisItem(item)"
          >
            <span class="itemTitle">
              {{ item.text }}
            </span>
            <img class="itemImage" :src="handlerImage(item.value)" />
          </div>
        </div>
      </div>
      <div
        class="confirmButton"
        :class="checkSuccess ? 'commomButtonHover' : ''"
        @click.stop="confirm"
        :style="{
          cursor: checkSuccess ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
      <div class="tips">
        {{ $t("createMemberMethodDialog_tips") }}
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "CreateMemberMethodDialog",
  props: {
    createMemberMethodDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeValue: 0,
      checkSuccess: true,
      selectItems: [
        {
          text: this.$t("createMemberMethod_0"),
          value: 0,
        },
        {
          text: this.$t("createMemberMethod_1"),
          value: 1,
        },
        {
          text: this.$t("createMemberMethod_2"),
          value: 2,
        },
      ],
    };
  },
  methods: {
    selectThisItem(item) {
      this.activeValue = item.value;
    },
    handlerImage(value) {
      if (this.activeValue == value) {
        return require("../../../../assets/images/teamManagement/selected.png");
      } else {
        return require("../../../../assets/images/teamManagement/notSelected.png");
      }
    },
    closeCreateMemberMethodDialog() {
      this.$emit("closeCreateMemberMethodDialog");
    },
    async confirm() {
      this.$emit("closeCreateMemberMethodDialog", this.activeValue);
    },
  },
};
</script>
<style lang="stylus" scoped>
.createMemberMethodDialog
  .dialogIcon
    height: 68px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    .icon
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background: #33CC66;
      display flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        color: white;
        font-size: 54px;
  .dialogBody
    width: 100%;
    flex 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    .contentBox
      width: 100%;
      flex: 1;
      min-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .selectBox
        width: 100%;
        height: 144px;
        .selectItem
          width: 100%;
          height: 48px;
          border-radius: 4px;
          transition: background 0.3s;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;
          &:hover
            background: #E6E6E6;
            cursor: pointer;
          .itemTitle
            font-size: 16px;
            color: #292D32;
          .itemImage
            width: 20px;
            height: 20px;
    .confirmButton
      width: 100%;
      height: 46px;
      border-radius: 4px;
      background: rgba(0,0,0,0.10);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      .iconStyle
        font-size: 24px;
        color: #FFFFFF;
    .tips
      width: 100%;
      font-size: 16px;
      color: #292D32;
      margin-top: 40px;
      margin-bottom: 25px;
      flex-shrink: 0;
      text-align: center;
      word-break: keep-all;
</style>
<style lang="stylus">
.createMemberMethodDialog
  .el-dialog
    margin-top 0 !important;
    padding 40px 80px 50px 80px !important;
    height: 544px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      padding 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
</style>
