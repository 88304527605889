<template>
  <el-dialog
    :visible.sync="showSelectDialog"
    width="500px"
    class="selectDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="closeSelectDialog"
    :destroy-on-close="true"
  >
    <div
      class="dialogBox"
      v-loading="sendLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div
        class="dialogBody"
        v-loading="membersLoading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#FFFFFF"
      >
        <div class="dialogIcon">
          <span class="iconfont_Me icon-send iconStyle"></span>
        </div>
        <div class="dialogSearch">
          <div class="searchBox">
            <el-input
              resize="none"
              maxlength="200"
              v-model="searchText"
              :placeholder="placeholderIcon"
              @input="searchChange"
            ></el-input>
          </div>
        </div>
        <div class="selectAll">
          <span class="title">{{ $t("selectAll") }}</span>
          <el-checkbox
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            v-model="checkAll"
          ></el-checkbox>
        </div>
        <div class="selectList">
          <div class="scroll">
            <el-checkbox-group
              v-model="checkedMembers"
              @change="handleCheckedMembersChange"
            >
              <div
                class="selectItem"
                v-for="member in membersList"
                :key="member.providerId"
              >
                <el-checkbox
                  class="itemCheckbox"
                  :label="member.providerId"
                  :disabled="member.freezeStatus"
                >
                  <div class="itemBody">
                    <div class="itemAvatar">
                      <Avatar
                        :path="member.profilePhoto"
                        :international="
                          member.country == '' ? 0 : toNumber(member.country)
                        "
                        :isFrozen="member.freezeStatus"
                      ></Avatar>
                    </div>
                    <div class="itemContent">
                      <div class="name">
                        {{ member.name }}
                      </div>
                      <div class="id">ID:&nbsp;&nbsp;{{ member.ucId }}</div>
                    </div>
                  </div>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <div class="selectedList" v-if="checkedMembers.length !== 0">
          <div
            class="direction direction_left"
            @click.stop="changePosition('left')"
            v-show="avatarListWidth > 480"
          >
            <span class="iconfont_Me icon-left iconStyle"></span>
          </div>
          <div
            class="direction direction_right"
            @click.stop="changePosition('right')"
            v-show="avatarListWidth > 480"
          >
            <span class="iconfont_Me icon-right iconStyle"></span>
          </div>
          <div
            class="avatarList"
            :style="{
              transform: `translate3d(${avatarListPosition}px,0,0)`,
              width: `${avatarListWidth}px`,
            }"
          >
            <div
              class="avatar"
              v-for="member in selectedList"
              :key="member.providerId"
            >
              <div class="close" @click.stop="deleteChecked(member.providerId)">
                <span class="iconfont_Me icon-x iconStyle"></span>
              </div>
              <Avatar
                :path="member.profilePhoto"
                :international="
                  member.country == '' ? 0 : toNumber(member.country)
                "
              ></Avatar>
            </div>
          </div>
        </div>
      </div>
      <div
        class="confirmButton"
        :class="{ commomButtonHover: checkedMembers.length !== 0 }"
        :style="{
          cursor: checkedMembers.length !== 0 ? 'pointer' : 'not-allowed',
        }"
        @click.stop="send"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
        <span class="countStyle" v-if="checkedMembers.length !== 0"
          >({{ checkedMembers.length }})</span
        >
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  getTeamInfo,
  sendNotification,
} from "../../../../api/newVersion/team/index";
import Avatar from "../../../components/avatarDisplay/index.vue";
export default {
  name: "SelectDialog",
  components: {
    Avatar,
  },
  props: {
    showSelectDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkAll: false,
      searchText: "",
      placeholderIcon: "\ue6af",
      membersLoading: true,
      membersList: [],
      checkedMembers: [],
      dataTotal: 0,
      pageSize: 9999,
      currentPage: 1,
      isIndeterminate: false,
      avatarListPosition: 20,
      sender: null,
      sendLoading: false,
    };
  },
  watch: {
    showSelectDialog: {
      async handler(val, old) {
        if (val && val !== old) {
          await this.getMembers();
        }
      },
      deep: true,
    },
    avatarListWidth: {
      async handler(val, old) {
        if (val && val !== old) {
          if (this.avatarListPosition < 20) {
            this.changePosition("right");
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    avatarListWidth() {
      return this.selectedList.length * 55;
    },
    providerIds() {
      return this.membersList
        .filter((member) => !member.freezeStatus)
        .map((member) => member.providerId);
    },
    selectedList() {
      if (this.checkedMembers.length !== 0) {
        return this.membersList.filter(
          (member) => this.checkedMembers.indexOf(member.providerId) !== -1
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    changePosition(position) {
      switch (position) {
        case "left":
          const left = this.avatarListPosition + 100;
          if (left >= 20) {
            this.avatarListPosition = 20;
          } else {
            this.avatarListPosition = left;
          }
          break;
        case "right":
          const difference = this.avatarListWidth - 480;
          const right = this.avatarListPosition - 100;
          if (this.avatarListWidth <= 480) {
            this.avatarListPosition = 20;
          } else if (-right < difference) {
            this.avatarListPosition = right;
          } else {
            this.avatarListPosition = -difference;
          }
          break;
      }
    },
    deleteChecked(id) {
      this.checkedMembers = this.checkedMembers.filter(
        (memberId) => memberId !== id
      );
      this.handleCheckedMembersChange(this.checkedMembers);
    },
    toNumber(str) {
      return parseInt(str);
    },
    async searchChange() {
      await this.getMembers();
    },
    async getMembers(
      params = {
        pageSize: this.pageSize,
        page: this.currentPage,
        searchWords: this.searchText,
      }
    ) {
      this.membersLoading = true;
      const result = await getTeamInfo(params);
      this.membersLoading = false;
      if (result.code == 200) {
        this.membersList = result.data.data.members.filter(
          (member) => member.identity !== 4
        );
        const sender = result.data.data.members.filter(
          (member) => member.identity === 4
        );
        if (sender.length !== 0) {
          this.sender = sender[0];
        }
        this.dataTotal = Number(result.data.data.totalMembers);
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
    handleCheckedMembersChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.providerIds.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.providerIds.length;
    },
    handleCheckAllChange(val) {
      this.checkedMembers = val ? this.providerIds : [];
      this.isIndeterminate = false;
    },
    closeSelectDialog() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$emit("closeSelectDialog");
    },
    async send() {
      if (this.sendLoading) {
        return;
      }
      if (this.checkedMembers.length === 0) {
        return;
      }
      this.sendLoading = true;
      const message = {
        ...this.$store.state.team.notification,
        ...this.sender,
        publishTime: this.getNowFormatDate(),
      };
      const param = {
        message: JSON.stringify(message),
        providerIds: this.checkedMembers,
        title: this.$store.state.team.notification.icanDo,
      };
      const result = await sendNotification(param);
      this.sendLoading = false;
      if (result.code == 200) {
        this.$store.commit("setTeamNotification", null);
        this.closeSelectDialog();
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
    // 获取当前的时间，用于拼凑首页帖子数据
    getNowFormatDate() {
      let date = new Date();
      let month = date.getMonth() + 1;
      let curDate = date.getDate();
      let curHours = date.getHours();
      let curMinutes = date.getMinutes();
      let curSeconds = date.getSeconds();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
      }
      if (curHours >= 0 && curHours <= 9) {
        curHours = "0" + curHours;
      }
      if (curMinutes >= 0 && curMinutes <= 9) {
        curMinutes = "0" + curMinutes;
      }
      if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
      }
      let currentdate = `${date.getFullYear()}-${month}-${curDate} ${curHours}:${curMinutes}:${curSeconds}`;
      return currentdate;
    },
  },
};
</script>
<style lang="stylus" scoped>
.selectDialog
  .dialogBox
    width: 100%;
    height: 100%;
    overflow hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  .dialogBody
    flex: 1;
    width: 100%;
    height: 100%;
    overflow hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .dialogIcon
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      flex-shrink: 0;
      min-height: 0;
      .iconStyle
        color: #373737;
        font-size:36px;
        line-height: 30px;
    .dialogSearch
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 40px;
      overflow hidden;
      flex-shrink: 0;
      min-height: 0;
      .searchBox
        width: 100%;
        height: 38px;
        border-radius: 19px;
        overflow hidden;
    .selectAll
      width: 100%;
      height: 50px;
      padding: 0 40px;
      margin-top: 20px;
      background: #f2f2f2;
      flex-shrink: 0;
      min-height: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title
        color #000000;
        font-size: 14px;
        user-select: none;
    .selectList
      flex 1;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar
        width: 2px;
      &::-webkit-scrollbar-thumb
        border-radius: 1px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
        background: #e6e6e6;
      &::-webkit-scrollbar-track
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      .scroll
        width: 100%;
        overflow hidden;
        padding-bottom: 20px;
        .selectItem
          width: 100%;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .itemBody
            flex 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor pointer;
            padding-left: 40px;
            box-sizing: border-box;
            &:hover
              background: #f7f7f7;
            .itemAvatar
              width: 50px;
              height: 50px;
              overflow hidden;
              flex-shrink: 0;
            .itemContent
              flex 1;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center
              flex-direction: column;
              box-sizing: border-box;
              padding-left: 10px;
              .name
                height: 24px;
                width: 100%;
                color #333333;
                font-size: 14px;
                line-height: 24px;
                font-weight: 700;
              .id
                height: 16px;
                width: 100%;
                text-align: left;
                color: #8F8F8F;
                font-size: 12px;
                line-height: 16px;
                user-select none;
    .selectedList
      width: 100%;
      height: 70px;
      flex-shrink: 0;
      background: #F2FCF8;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position relative;
      .direction
        width: 22px;
        height: 22px;
        cursor pointer;
        border-radius: 50%;
        position: absolute;
        top: 23px;
        background: rgba(0,0,0,0.5);
        z-index 1;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.2s;
        &:hover
          background: rgba(0,0,0,0.4);
        .iconStyle
          color: #FFFFFF;
          font-size: 16px;
      .direction_left
        left 10px;
      .direction_right
        right 10px;
      .avatarList
        height: 45px;
        white-space: nowrap;
        transition: transform 0.5s;
        .avatar
          width: 45px;
          height: 45px;
          margin-right: 10px;
          display: inline-block;
          position: relative;
          &:hover
            .close
              display: flex;
              cursor pointer;
              align-items: center;
              justify-content: center;
          .close
            position: absolute;
            top 0;
            right: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: rgba(0,0,0,0.40);
            z-index 2;
            display: none;
            .iconStyle
              font-size: 14px
              color: #FFFFFF;
  .confirmButton
    width: 150px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7.5px auto 18.5px auto;
    position relative;
    .iconStyle
      font-size: 22px;
      color: #FFF;
    .countStyle
      color #fff;
      font-size: 14px;
      line-height: 24px;
</style>
<style lang="stylus">
.selectDialog
  .el-dialog
    margin-top 0 !important;
    padding 0 !important;
    height: 700px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      padding 0 !important;
      overflow hidden;
      .searchBox
        .el-input
          background: rgba(0,0,0,0.05) !important;
          .el-input__inner
            border none !important;
            font-family 'iconfont_Me';
            caret-color #33CC66;
            font-size 14px !important;
            color #333333 !important;
            background: rgba(0,0,0,0.05) !important;
            &::placeholder
              font-size: 16px
      .selectAll
        span
          display: block;
        .el-checkbox__input
          flex-shrink: 0;
          min-width: 0;
          .el-checkbox__inner
            border-radius: 50%;
            width: 16px;
            height: 16px;
            &::after
              left: 5px;
              top: 2px;
      .itemCheckbox
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row-reverse;
        padding 0 40px;
        box-sizing: border-box;
        span
          display: block;
        .el-checkbox__input
          flex-shrink: 0;
          min-width: 0;
          .el-checkbox__inner
            border-radius: 50%;
            width: 16px;
            height: 16px;
            &::after
              left: 5px;
              top: 2px;
        .el-checkbox__label
          flex 1;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          padding-left: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
      .confirmButton
        .el-loading-mask
          display: flex;
          align-items: center;
          justify-content: center;
          .el-loading-spinner
            position relative;
            margin-top: 0;
            top: 0;
</style>
