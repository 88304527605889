var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "membersBox teamAccount_membersList" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.members, height: "100%" },
          on: { "row-click": _vm.openMemberInfoDialog }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("userName"), width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "userInfo" }, [
                      _c(
                        "div",
                        { staticClass: "userPhoto" },
                        [
                          _c("Avatar", {
                            attrs: {
                              path: scope.row.profilePhoto,
                              international:
                                scope.row.country == ""
                                  ? 0
                                  : _vm.toNumber(scope.row.country),
                              isFrozen: scope.row.freezeStatus,
                              isOwnerOfTheTeam:
                                scope.row.identity == 4 ? true : false
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "userIdBox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "name userStyle",
                            class: scope.row.vipStatus ? "xvipName" : "",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            },
                            attrs: { title: scope.row.name }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.name) +
                                "\n              "
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("paidMember"),
                                  placement: "bottom",
                                  "popper-class": "me-tooltip-effect"
                                }
                              },
                              [
                                _c("VipIcon", {
                                  staticStyle: { "margin-left": "2px" },
                                  attrs: {
                                    vipStatus: scope.row.vipStatus,
                                    iconWidth: 18,
                                    id: scope.row.id
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "id userStyle",
                            style: {
                              color: scope.row.updatePassword ? "" : "#183CF3"
                            },
                            attrs: { title: scope.row.ucId }
                          },
                          [
                            _vm._v(
                              "\n              ID: " +
                                _vm._s(scope.row.ucId) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("role"), width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.transformRoles(scope.row.identity)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "emails",
              label: _vm.$t("emailAddress"),
              width: "250"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function({ row }) {
                  return [
                    row.emails.length > 0
                      ? _c("span", {
                          staticClass: "emailText",
                          attrs: { title: _vm.filterBindCondition(row) },
                          domProps: {
                            innerHTML: _vm._s(_vm.filterBindCondition(row))
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "joinTime",
              label: _vm.$t("joiningTime"),
              width: "160"
            }
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("operation"), width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function({ row }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "operating" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: row.freezeStatus
                                ? _vm.$t("unfreeze")
                                : _vm.$t("frozen"),
                              placement: "bottom"
                            }
                          },
                          [
                            row.identity != 4
                              ? _c("img", {
                                  staticClass: "buttonStyle",
                                  style: {
                                    width: "24px",
                                    height: "24px",
                                    "margin-left": "0px"
                                  },
                                  attrs: {
                                    src: _vm.transformFreezeStatus(
                                      row.freezeStatus
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.changeFreezeStatus(row)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("record"),
                              placement: "bottom"
                            }
                          },
                          [
                            row.identity != 4
                              ? _c("img", {
                                  staticClass: "buttonStyle",
                                  style: { width: "24px", height: "24px" },
                                  attrs: {
                                    src: require("../../../assets/images/teamManagement/records.png")
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.openRecordDialog(row)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("unbindEmail"),
                              placement: "bottom"
                            }
                          },
                          [
                            row.identity != 4 &&
                            row.emails.length != 0 &&
                            _vm.checkBindCondition(row.emails)
                              ? _c("img", {
                                  staticClass: "buttonStyle",
                                  style: { width: "24px", height: "24px" },
                                  attrs: {
                                    src: require("../../../assets/images/teamManagement/unbindEmail.png")
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.unbindEmail(row)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.members.length === 0
        ? _c(
            "div",
            { staticClass: "empty" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 1 } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }