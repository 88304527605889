var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "createdEmailOrIDSuccessDialog",
      attrs: {
        visible: _vm.createdEmailOrIDSuccessDialog,
        width: "640px",
        "close-on-click-modal": false,
        "before-close": _vm.closeCreatedEmailOrIDSuccessDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.createdEmailOrIDSuccessDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogIcon" }, [
        _c("img", {
          staticClass: "iconImage",
          attrs: {
            src: require("../../../../assets/images/teamManagement/success_icon.png")
          }
        }),
        _vm._v("\n    " + _vm._s(_vm.$t("createdSuccessfully")) + "\n  ")
      ]),
      _c("div", { staticClass: "dialogBody" }, [
        _c("div", { staticClass: "contentBox" }, [
          _c(
            "div",
            { staticClass: "contentItem", attrs: { title: _vm.melinkedID } },
            [
              _c("span", { staticClass: "itemTitle" }, [
                _vm._v("Melinked ID:")
              ]),
              _vm._v("\n        " + _vm._s(_vm.melinkedID) + "\n      ")
            ]
          ),
          _c(
            "div",
            { staticClass: "contentItem", attrs: { title: _vm.emailAddress } },
            [
              _c("span", { staticClass: "itemTitle" }, [
                _vm._v(_vm._s(_vm.$t("emailAddress")) + ":")
              ]),
              _vm._v("\n        " + _vm._s(_vm.emailAddress) + "\n      ")
            ]
          ),
          _c("div", { staticClass: "contentItem" }, [
            _c("span", { staticClass: "itemTitle" }, [
              _vm._v(
                _vm._s(
                  _vm.from == "method_1"
                    ? `ID/${_vm.$t("emailPassword")}`
                    : `${_vm.$t("emailPassword")}`
                ) + ":"
              )
            ]),
            _vm._v("\n        " + _vm._s(_vm.emailPassword) + "\n      ")
          ])
        ]),
        _c("div", { staticClass: "buttonBox" }, [
          _vm.from == "method_2"
            ? _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "buttonStyle leftButtonStyle",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.sendNotifications.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("img", {
                    style: {
                      width: "24px",
                      height: "24px"
                    },
                    attrs: {
                      src: require("../../../../assets/images/teamManagement/broadcast.png")
                    }
                  })
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "buttonStyle rightButtonStyle",
              style: {
                width: _vm.from == "method_2" ? "" : "100%"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startCopy.apply(null, arguments)
                }
              }
            },
            [
              _c("img", {
                style: {
                  width: "24px",
                  height: "24px"
                },
                attrs: {
                  src: require("../../../../assets/images/teamManagement/copy.png")
                }
              })
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }