var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "publishPage", staticClass: "publishPage" },
    [
      _c("div", { staticClass: "publishHeader" }, [
        _c(
          "div",
          {
            staticClass: "back",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("closeEditor")
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-left iconStyle" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "mainDisplayArea" },
        [
          _c("EditorQuill", {
            attrs: {
              uploadedAttachmentsStatus: _vm.uploadedAttachmentsStatus,
              publishPageHeight: _vm.publishPageHeight,
              bottomOperatingAreaHeight: _vm.bottomOperatingAreaHeight
            },
            on: { getPublishPageHeight: _vm.getPublishPageHeight }
          }),
          _c(
            "div",
            { staticClass: "operatingArea" },
            [
              _c("BottomOperatingArea", {
                ref: "bottomOperatingArea",
                on: {
                  hasUploadedAttachments: _vm.hasUploadedAttachments,
                  selectRecipient: _vm.selectRecipient
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("SelectDialog", {
        attrs: { showSelectDialog: _vm.showSelectDialog },
        on: { closeSelectDialog: _vm.closeSelectDialog }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }