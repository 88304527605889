var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "bottomContent", staticClass: "bottomContent" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.whetherToShow,
            expression: "whetherToShow"
          }
        ],
        staticClass: "showUploadedArea"
      },
      [
        _vm.canUploadPicture && _vm.uploadedPicture.length != 0
          ? _c("ShowPictureArea", {
              attrs: { uploadedPicture: _vm.uploadedPicture },
              on: {
                needDeletePictrue: _vm.deleteThisPictrue,
                needCanclePictrue: _vm.cancleThisPictrue,
                needUploadPictrue: _vm.selectThisUploadButton
              }
            })
          : _vm._e(),
        _vm.canUploadVideo && _vm.uploadedVideo.length != 0
          ? _c("ShowVideoArea", {
              attrs: { uploadedVideo: _vm.uploadedVideo[0] },
              on: {
                needDeleteVideo: _vm.deleteThisVideo,
                needCancleVideo: _vm.cancleThisVideo,
                getDuration: _vm.getDuration
              }
            })
          : _vm._e(),
        _vm.canUploadFile && _vm.uploadedFile.length != 0
          ? _c("ShowFileItem", {
              attrs: { fileInfo: _vm.uploadedFile[0], needDelete: true },
              on: {
                deleteThisFile: _vm.deleteThisFile,
                cancleThisFile: _vm.cancleThisFile
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "buttonArea" },
      [
        _c(
          "div",
          { staticClass: "uploadArea" },
          _vm._l(_vm.uploadButtons, function(item, index) {
            return _c(
              "div",
              {
                key: item.iconClass,
                staticClass: "uploadStyle",
                style: {
                  background: item.isSelected ? "#F2FCF8" : "",
                  cursor: _vm.attachmentUploaded(item.iconClass)
                    ? "not-allowed"
                    : "pointer"
                },
                on: {
                  click: function($event) {
                    return _vm.selectThisUploadButton(index)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me iconStyle",
                  class: [
                    item.iconClass,
                    item.isSelected ? "iconSelected" : ""
                  ],
                  style: {
                    color: _vm.attachmentUploaded(item.iconClass)
                      ? "#8F8F8F"
                      : ""
                  }
                })
              ]
            )
          }),
          0
        ),
        _c(
          "el-button",
          {
            staticClass: "submitArea commomButtonHover",
            class: _vm.disabledSubmit ? "disabledSubmit" : "",
            attrs: { disabled: _vm.disabledSubmit },
            on: { click: _vm.startSubmit }
          },
          [_c("span", { staticClass: "iconfont_Me icon-send iconStyle" })]
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ]
      },
      [
        _c("el-upload", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "uploadPicture",
          attrs: {
            action: "/",
            accept: "image/*",
            multiple: true,
            "http-request": _vm.handlerUpload,
            "before-upload": _vm.beforePictrueUpload
          }
        }),
        _c("el-upload", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "uploadVideo",
          attrs: {
            action: "/",
            accept: "video/*",
            multiple: false,
            "http-request": _vm.handlerUpload,
            "before-upload": _vm.beforeVideoUpload
          }
        }),
        _c("el-upload", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "uploadFile",
          attrs: {
            action: "/",
            accept: _vm.$store.state.publishArticle.attachmentType,
            multiple: false,
            "http-request": _vm.handlerUpload,
            "before-upload": _vm.beforeFileUpload
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }