<template>
  <el-dialog
    :visible.sync="showMemberInfoDialog"
    width="732px"
    class="memberInfoDialog"
    :close-on-click-modal="false"
    :before-close="closeMemberInfoDialog"
  >
    <div class="dialogHeader">
      <div class="icon">
        <img
          :src="
            require('../../../../assets/images/email/loginDialog/success_2.png')
          "
        />
      </div>
      {{ $t("createdSuccessfully") }}
    </div>
    <div class="dialogBody">
      <div class="outerBox" v-if="selectedRow">
        <div class="textBox">
          <span class="title">Melinked ID: </span>
          <span class="fontColor" :title="selectedRow.ucId">{{
            selectedRow.ucId
          }}</span>
        </div>
        <div class="textBox">
          <span class="title">pwd: </span>
          <span class="fontColor">{{
            showPassword ? selectedRow.initPassword : "******"
          }}</span>
          <div class="showOrHide" @click.stop="changePasswordState">
            <span
              class="iconfont_Me icon-Show iconStyle"
              :class="!showPassword ? 'icon-Hide' : 'icon-Show'"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmButton commomButtonHover" @click.stop="startCopy">
      <span class="iconfont_Me icon-a-zu5517 iconStyle"></span>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "MemberInfoDialog",
  props: {
    showMemberInfoDialog: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  mounted() {
    this.showPassword = false;
  },
  methods: {
    closeMemberInfoDialog() {
      this.$emit("closeMemberInfoDialog");
    },
    changePasswordState() {
      this.showPassword = !this.showPassword;
    },
    // 点击复制按钮
    startCopy() {
      this.$copyText(
        `${this.$t("createMelinkedIDCopyPasteContent")}\r
        URL: https://www.melinked.com\r
        Melinked ID: ${this.selectedRow.ucId}\r
        pwd: ${this.selectedRow.initPassword}\r`
      ).then(
        (res) => {
          this.$message({
            customClass: "meetingMessage",
            dangerouslyUseHTMLString: true,
            message:
              '<div style="line-height:22px;display:flex;flex-direction:row;align-items:flex-start;justify-content:center" >' +
              '<div class="copySucceeded"></div>' +
              "<span>" +
              this.$t("copySucceeded") +
              "</span>" +
              "</div>",
            duration: 1000,
          });
          this.closeMemberInfoDialog();
        },
        (err) => {
          this.$message({
            type: "error",
            message: this.$t("copyFailed"),
          });
          this.dialogClose();
        }
      );
    },
  },
};
</script>
<style lang="stylus" scoped>
.memberInfoDialog
  .dialogHeader
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 35px;
    color: #292D32;
    font-size: 20px;
    line-height: 28px;
    .icon
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-right: 12px;
      img
        width: 100%;
        height: 100%;
  .dialogBody
    width: 588px;
    height: 200px;
    background: rgba(0,0,0,0.03);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .outerBox
      min-width: 0;
      overflow hidden;
      max-width: 70%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 64px;
      .textBox
        font-size: 16px;
        letter-spacing: 0.7px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 22px;
        .title
          color: #858585;
          width: 110px;
          flex-shrink: 0;
          display: block;
          height: 100%;
          text-align: right;
        .fontColor
          color: #292D32;
          padding-left: 10px;
          flex: 1;
          min-width: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2; // 设置两行文字溢出
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        .showOrHide
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor pointer;
          margin-left: 10px;
          .iconStyle
            font-size: 20px;
  .confirmButton
    width: 360px;
    height: 46px;
    border-radius: 4px;
    background: rgba(0,0,0,0.10);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    .iconStyle
      font-size: 22px;
      color: #FFF;
</style>
<style lang="stylus">
.memberInfoDialog
  .el-dialog
    margin-top 0 !important;
    padding 30px 40px !important;
    max-height: 432px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
</style>
