const toolOptions = [
  ["bold"],
  [{ header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  // ['richTextPicture','richTextVideo']
]
export default {
  placeholder: '',
  modules: {
    toolbar: {
      container: toolOptions
    }
  },
  initCustomButton() {
    // const richTextPicture = document.querySelector('.ql-richTextPicture');
    // const richTextVideo = document.querySelector('.ql-richTextVideo');
    // richTextPicture.style.cssText = "width:auto;display: flex;align-items: center;justify-content: center;margin-right:15px";
    // richTextVideo.style.cssText = "width:auto;display: flex;align-items: center;justify-content: center";
    // richTextPicture.innerHTML="<span class='iconfont_Me icon-picture1' style='font-size:24px;color:#333333;font-weight:bold'></span>";
    // richTextVideo.innerHTML="<span class='iconfont_Me icon-video' style='font-size:24px;color:#333333;font-weight:bold'></span>";
  }
};