<template>
  <div class="teamNotification">
    <div class="notificationHeader"></div>
    <div
      class="notificationBox"
      v-loading="notificationLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div class="searchAndAddBox">
        <div class="searchBox">
          <el-input
            resize="none"
            maxlength="200"
            v-model="searchText"
            :placeholder="placeholderIcon"
            @input="searchChange"
          ></el-input>
        </div>
        <div class="sendBox" @click.stop="send">
          <img
            :src="
              require('../../../assets/images/teamManagement/sendNotification.png')
            "
          />
        </div>
      </div>
      <NotificationTable
        :notifications="notificationList"
        @getNotificationList="getNotificationList"
      ></NotificationTable>
    </div>
    <div class="notificationPagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="dataTotal"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <div class="notificationEditor" v-if="notificationEditor">
      <NotificationEditor @closeEditor="closeEditor"></NotificationEditor>
    </div>
  </div>
</template>
<script>
import { teamNotification } from "../../../api/newVersion/team/index";
import NotificationTable from "./notificationTable.vue";
import NotificationEditor from "./notificationEditor";
export default {
  name: "TeamNotification",
  components: {
    NotificationTable,
    NotificationEditor,
  },
  data() {
    return {
      notificationLoading: false,
      placeholderIcon: "\ue6af",
      searchTimer: null,
      searchText: "",
      pageSize: 20,
      currentPage: 1,
      notificationList: [],
      dataTotal: 0,
      notificationEditor: false,
    };
  },
  destroyed() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  },
  props: {
    activeName: {
      type: String,
      default: "",
    },
  },
  watch: {
    activeName: {
      async handler(val, old) {
        if (val == "notification") {
          // 重置一下data数据
          Object.assign(this.$data, this.$options.data.call(this));
          this.$store.dispatch("commitResetTeamEditorStore");
          await this.$nextTick();
          await this.getNotificationList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async currentChange(page) {
      this.currentPage = page;
      await this.getNotificationList();
    },
    async getNotificationList(
      params = {
        pageSize: this.pageSize,
        page: this.currentPage,
        searchWords: this.searchText,
      }
    ) {
      this.notificationLoading = true;
      const result = await teamNotification(params);
      this.notificationLoading = false;
      if (result.code == 200) {
        this.notificationList = result.data.data.map((item) => {
          item.message = JSON.parse(item.message);
          return item;
        });
        this.dataTotal = Number(result.data.total);
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
    searchChange() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(async () => {
        await this.getNotificationList();
      }, 500);
    },
    send() {
      this.notificationEditor = true;
    },
    async closeEditor() {
      this.notificationEditor = false;
      await this.getNotificationList();
    },
  },
};
</script>
<style lang="stylus" scoped>
.teamNotification
  width: 100%;
  height: 100%;
  overflow hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  .notificationHeader
    width: 100%;
    min-height: 0;
  .notificationBox
    width: 100%;
    overflow hidden;
    flex 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #ffffff;
    .searchAndAddBox
      width: 100%;
      height: 92px;
      flex-shrink: 0;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchBox
        width: 293px;
        height: 38px;
        border-radius: 19px;
        margin-left: 35px;
        overflow hidden;
      .sendBox
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: #33CC66
        margin-right: 35px;
        cursor pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img
          width: 32px;
          height: 32px;
          display: block;
  .notificationPagination
    width: 100%;
    height: 65px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  .notificationEditor
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index 1;
    position: absolute;
    background: #FFFFFF;
    overflow hidden;
</style>
<style lang="stylus">
.teamNotification
  .notificationBox
    .searchAndAddBox
      .searchBox
        .el-input
          background: #F2F2F2 !important;
          .el-input__inner
            border none !important;
            font-family 'iconfont_Me';
            caret-color #33CC66;
            font-size 14px !important;
            color #333333 !important;
            background: #F2F2F2 !important;
            &::placeholder
              font-size: 16px
  .notificationPagination
    .el-pagination
      .btn-prev
        height 40px !important;
        border-radius 50% !important;
      .btn-next
        height 40px !important;
        border-radius 50% !important;
      .el-pager
        li
          height 40px !important;
          line-height: 40px !important;
          border-radius 50% !important;
</style>
