var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.myTeamMembersDialog,
        width: "560px",
        center: "",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "append-to-body": true,
        "custom-class": "myTeamMembersDialog",
        top: "0",
        "before-close": _vm.closeDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.myTeamMembersDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogBody" }, [
        _c("span", {
          staticClass: "iconfont_Me icon-x closeDialogStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.closeDialog.apply(null, arguments)
            }
          }
        }),
        _c("div", { staticClass: "dialogHeader" }, [
          _vm._v(_vm._s(_vm.$t("myTeamMembers")))
        ]),
        _c("div", { staticClass: "listArea" }, [
          _c("div", { staticClass: "listBox" }, [
            _c("div", { staticClass: "boxHeader" }, [
              _c(
                "div",
                { staticClass: "searchInput" },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-search iconStyle",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.getMembersList()
                      }
                    }
                  }),
                  _c("el-input", {
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.getMembersList()
                      }
                    },
                    model: {
                      value: _vm.searchKey,
                      callback: function($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "listCoreBox", on: { scroll: _vm.coreBoxScroll } },
              [
                _vm.membersList.length != 0
                  ? _vm._l(_vm.membersList, function(data) {
                      return _c(
                        "div",
                        {
                          key: data.ucId,
                          staticClass: "listItem",
                          style: {
                            cursor: data.freezeStatus
                              ? "not-allowed"
                              : "pointer"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.selectedThisItem(data)
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "itemImage",
                            attrs: { src: _vm.handlerImage(data.ucId) }
                          }),
                          _c(
                            "div",
                            { staticClass: "userPhoto" },
                            [
                              _c("Avatar", {
                                attrs: {
                                  path: data.profilePhoto,
                                  international:
                                    data.country == ""
                                      ? 0
                                      : _vm.toNumber(data.country),
                                  isFrozen: data.freezeStatus,
                                  isOwnerOfTheTeam:
                                    data.identity == 4 ? true : false
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "itemTitle" }, [
                            _vm._v("ID:" + _vm._s(data.ucId))
                          ])
                        ]
                      )
                    })
                  : _c(
                      "div",
                      { staticClass: "placeholderMap" },
                      [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
                      1
                    )
              ],
              2
            )
          ])
        ]),
        _c("div", { staticClass: "footer" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "buttonStyle confirm",
              class: !_vm.selectedItem ? "disableStyle" : "",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirm.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }