var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "freezeStatusDialog",
      attrs: {
        visible: _vm.showFreezeStatusDialog,
        width: "480px",
        "close-on-click-modal": false,
        "before-close": _vm.closeFreezeStatusDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showFreezeStatusDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ],
          staticClass: "dialogBody",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _vm.selectedRow
            ? _c("div", { staticClass: "dialogHeader" }, [
                _vm._v("\n      " + _vm._s(_vm.dialogTitle) + "\n    ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "buttonBox" }, [
            _c(
              "div",
              {
                staticClass: "buttonStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeFreezeStatusDialog.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-x iconStyle" })]
            ),
            _c(
              "div",
              {
                staticClass: "buttonStyle commomButtonHover",
                style: { "margin-left": "10px" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.change.apply(null, arguments)
                  }
                }
              },
              [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }