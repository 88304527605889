<template>
  <el-dialog
    :visible.sync="showAddMemberDialog"
    width="480px"
    class="addMemberDialog"
    :close-on-click-modal="false"
    :before-close="closeAddMemberDialog"
    :destroy-on-close="true"
  >
    <div class="dialogIcon">
      <div class="icon">
        <span class="iconfont_Me icon-user-fill iconStyle"></span>
      </div>
    </div>
    <div
      class="dialogBody"
      v-loading="dialogLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div class="input">
        <div class="inputTitle">Melinked ID</div>
        <div class="inputBox">
          <CommonInput
            @inputChange="getAccountText"
            :maxlength="100"
            :defaultInputText="userName"
            :needInputshowError="needInputshowError"
            :checkAccountSuccess="checkAccountSuccess"
            :noChinese="true"
          >
            <span class="iconfont_Me icon-user-fill iconStyle"></span>
          </CommonInput>
        </div>
        <div class="errorBox" v-if="needInputshowError">
          {{ addTeamMemberTip }}
        </div>
      </div>
      <div
        class="confirmButton"
        :class="checkAccountSuccess ? 'commomButtonHover' : ''"
        @click.stop="confirm"
        :style="{
          cursor: checkAccountSuccess ? 'pointer' : 'not-allowed',
        }"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import CommonInput from "../../../components/inputComponent/commonInput.vue";
import { addMembers } from "@/api/newVersion/team";
import { existsByName } from "@/api/newVersion/loginAndRegister";
export default {
  name: "AddMemberDialog",
  components: {
    CommonInput,
  },
  props: {
    showAddMemberDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: "",
      needInputshowError: false,
      checkAccountSuccess: false,
      timer: null,
      dialogLoading: false,
      addTeamMemberTip: this.$t("teamEmailInputError_3"),
    };
  },
  methods: {
    closeAddMemberDialog() {
      this.$emit("closeAddMemberDialog");
    },
    async getAccountText(inputText) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (inputText !== "") {
        this.timer = setTimeout(async () => {
          let result = await existsByName(inputText);
          if (result.code == 200 && !result.data.data) {
            this.userName = inputText;
            this.checkAccountSuccess = true;
          } else if (result.data.data) {
            this.checkAccountSuccess = false;
          }
          this.needInputshowError = !this.checkAccountSuccess;
        }, 500);
      } else {
        this.checkAccountSuccess = false;
        this.needInputshowError = false;
      }
    },
    async confirm() {
      if (this.checkAccountSuccess) {
        this.dialogLoading = true;
        const result = await addMembers({
          memberName: this.userName,
        });
        this.dialogLoading = false;
        if (result.code == 200) {
          this.userName = "";
          this.$emit("closeAddMemberDialog", true, result.data.data);
        } else {
          this.needInputshowError = true;
          this.addTeamMemberTip = result.message;
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.addMemberDialog
  .dialogIcon
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: #33CC66;
      display flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        color: white;
        font-size: 32px;
  .dialogBody
    width: 100%;
    flex 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    padding 0 20px;
    .input
      width: 100%;
      overflow hidden;
      .inputTitle
        width: 100%;
        overflow hidden;
        font-size: 14px;
        color: #999999;
        text-align: left;
        padding-top: 28px;
        margin-bottom: 15px;
      .inputBox
        height: 46px;
        width: 100%;
      .errorBox
        padding-top: 10px;
        color #F57575;
        font-size: 13px;
        text-align: left;
        line-height: 16px;
    .confirmButton
      width: 360px;
      height: 46px;
      border-radius: 4px;
      background: rgba(0,0,0,0.10);
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        font-size: 22px;
        color: #FFF;
</style>
<style lang="stylus">
.addMemberDialog
  .el-dialog
    margin-top 0 !important;
    padding 30px 40px !important;
    height: 368px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    .el-dialog__header
      width: 100%;
      padding 0 !important;
      text-align: center;
      color: #343434;
      line-height: 20px;
      font-size: 14px;
      flex-shrink: 0;
    .el-dialog__body
      padding 9px 0 0 0 !important;
      width: 100%;
      flex 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      .el-input
        .el-input__inner
          border none !important;
          font-family 'iconfont_Me';
          caret-color #33CC66;
          font-size 14px !important;
          color #333333 !important;
</style>
