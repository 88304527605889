var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.notificationsLoading,
          expression: "notificationsLoading"
        }
      ],
      staticClass: "notificationTable",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.notifications },
          on: { "row-click": _vm.rowClick }
        },
        [
          _c("el-table-column", {
            attrs: { label: _vm.$t("noticeContent"), width: "320" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "contentBox" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getItemText(scope.row)) +
                          "\n        "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("timeOfPublication"), width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", { staticClass: "contentBox" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.createTime) +
                          "\n        "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "joinTime",
              label: _vm.$t("statusOfThePublication"),
              width: "180"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      staticClass: "iconfont_Me icon-choice-fill iconStyle"
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("operation") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", {
                      staticClass: "iconfont_Me icon-delet operatingIconStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.deleteNotification(scope.row.messageId)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.notifications.length === 0
        ? _c(
            "div",
            { staticClass: "empty" },
            [_c("PlaceholderMap", { attrs: { placeholderType: 1 } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }