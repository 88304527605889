<template>
  <div class="publishPage" ref="publishPage">
    <div class="publishHeader">
      <div class="back" @click.stop="$emit('closeEditor')">
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
    </div>
    <div class="mainDisplayArea">
      <EditorQuill
        @getPublishPageHeight="getPublishPageHeight"
        :uploadedAttachmentsStatus="uploadedAttachmentsStatus"
        :publishPageHeight="publishPageHeight"
        :bottomOperatingAreaHeight="bottomOperatingAreaHeight"
      ></EditorQuill>
      <div class="operatingArea">
        <BottomOperatingArea
          ref="bottomOperatingArea"
          @hasUploadedAttachments="hasUploadedAttachments"
          @selectRecipient="selectRecipient"
        ></BottomOperatingArea>
      </div>
    </div>
    <SelectDialog
      :showSelectDialog="showSelectDialog"
      @closeSelectDialog="closeSelectDialog"
    ></SelectDialog>
  </div>
</template>
<script>
import EditorQuill from "./editorQuill/index";
import BottomOperatingArea from "./bottomOperatingArea";
import SelectDialog from "./selectDialog.vue";
export default {
  name: "NotificationEditor",
  data() {
    return {
      // 是否已上传过附件
      uploadedAttachmentsStatus: "",
      // 当前发布页的高度
      publishPageHeight: 0,
      // 底部区域的高度
      bottomOperatingAreaHeight: 0,
      showSelectDialog: false,
    };
  },
  components: {
    EditorQuill,
    BottomOperatingArea,
    SelectDialog,
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {});
  },
  computed: {
    notification() {
      return this.$store.state.team.notification;
    },
  },
  methods: {
    // 已上传了附件
    hasUploadedAttachments(status) {
      this.uploadedAttachmentsStatus = status;
    },
    // 获取当前发布页的高度
    getPublishPageHeight() {
      // 获取发布页的高度
      this.publishPageHeight = this.$refs.publishPage.offsetHeight - 16;
      // 获取底部区域的高度
      this.bottomOperatingAreaHeight = this.$refs.bottomOperatingArea.getBottomOperatingAreaHeight();
    },
    selectRecipient() {
      this.showSelectDialog = true;
    },
    closeSelectDialog() {
      this.$emit("closeEditor");
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.publishPage
  width 100%;
  height 100%;
  box-sizing border-box;
  background #FFFFFF;
  overflow hidden;
  display flex;
  align-items flex-start;
  justify-content flex-start;
  flex-direction: column;
  .publishHeader
    width: 100%;
    height: 70px;
    overflow hidden;
    display: flex;
    align-items: center;
    padding 0 35px;
    box-sizing border-box;
    .back
      width: 24px;
      height: 24px;
      cursor pointer;
      border-radius: 50%;
      background: #E8E8E8;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        color: #000000;
        font-size: 14px;
  .mainDisplayArea
    width 100%;
    max-height 100%;
    padding 0 20px;
    box-sizing border-box;
    background #fff;
    overflow auto;
    .operatingArea
      width 100%;
      padding 0 15px;
      box-sizing border-box;
      .expandCollapse
        width 100%;
        height 20px;
        display flex;
        cursor pointer;
        align-items center;
        justify-content center;
        background transparent;
        .iconStyle
          color #8F8F8F;
          font-size 16px;
  .statusPopups
    top 50%;
    left 50%;
    transform translate3d(-50%,-50%,0);
    position fixed;
    z-index 999;
    width 9.375vw;
    height 9.375vw;
    border-radius 8px;
    background rgba(0,0,0,0.5);
    display flex;
    align-items center;
    justify-content center;
    .popupsInner
      width 3.65vw;
      height 3.65vw;
      border-radius 50%;
      overflow hidden;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 1.875vw;
        color #ffffff;
</style>
