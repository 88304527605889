var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "createEmailForMemberDialog",
      attrs: {
        visible: _vm.createEmailForMemberDialog,
        width: "640px",
        "close-on-click-modal": false,
        "before-close": _vm.closeCreateEmailForMemberDialog,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.createEmailForMemberDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogIcon" }, [
        _c("div", { staticClass: "icon" }, [
          _c("span", { staticClass: "iconfont_Me icon-user-fill iconStyle" })
        ])
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dialogLoading,
              expression: "dialogLoading"
            }
          ],
          staticClass: "dialogBody",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(255,255,255,0.3)"
          }
        },
        [
          _c("div", { staticClass: "contentBox" }, [
            _c("div", { staticClass: "infoBox" }, [
              _vm._v("Melinked ID: " + _vm._s(_vm.melinkedID))
            ]),
            _c("div", { staticClass: "inputBox" }, [
              _c(
                "div",
                { staticClass: "inputOutter" },
                [
                  _c(
                    "CommonInput",
                    {
                      attrs: {
                        mailbox: _vm.handlerDomain,
                        needInputshowError: _vm.emailInputshowError,
                        checkAccountSuccess: _vm.checkAccountSuccess,
                        maxlength: _vm.inputLength,
                        noChinese: true
                      },
                      on: { inputChange: _vm.getEmailText }
                    },
                    [
                      _c("el-image", {
                        style: {
                          width: "28px",
                          height: "28px"
                        },
                        attrs: {
                          src: require("../../../../assets/images/email/loginDialog/email_not_selected.png"),
                          fit: "cover"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm.emailInputshowError
              ? _c("div", { staticClass: "failedText" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.teamEmailInputError) + "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "confirmButton",
              class: _vm.checkSuccess ? "commomButtonHover" : "",
              style: {
                cursor: _vm.checkSuccess ? "pointer" : "not-allowed"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirm.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }