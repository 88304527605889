<template>
  <div class="narrowBox teamManagement">
    <template v-if="domainManagement">
      <div class="domainManagementBlock">
        <DomainManagement @closeDomainManagement="domainManagement = false" />
      </div>
    </template>
    <template v-else>
      <el-tabs
        :tab-position="tabPosition"
        class="settings-tabs"
        v-model="active"
      >
        <el-tab-pane name="myTeam">
          <div slot="label" class="tabTitle">
            <div class="iconBox">
              <span class="iconfont_Me icon-a-zu9560"></span>
            </div>
            <span class="settingTitle">
              {{ $t("myTeam") }}
            </span>
          </div>
          <div class="managementPage">
            <ManagementList
              :activeName="active"
              @openDomainManagement="openDomainManagement"
            ></ManagementList>
          </div>
        </el-tab-pane>
        <el-tab-pane name="notification">
          <div slot="label" class="tabTitle">
            <div class="iconBox">
              <img
                :src="
                  require('../../../assets/images/teamManagement/manageNoti.png')
                "
                :style="{ width: '24px', height: '24px' }"
              />
            </div>
            <span class="settingTitle">
              {{ $t("manageNotifications") }}
            </span>
          </div>
          <div class="managementPage">
            <TeamNotification :activeName="active"></TeamNotification>
          </div>
        </el-tab-pane>
      </el-tabs>
    </template>
  </div>
</template>
<script>
import Avatar from "../../components/avatarDisplay";
import ManagementList from "./myTeam.vue";
import TeamNotification from "./notification.vue";
import DomainManagement from "./domainManagement.vue";
export default {
  name: "Setting",
  data() {
    return {
      tabPosition: "left",
      active: "myTeam",
      domainManagement: false,
    };
  },
  components: {
    Avatar,
    ManagementList,
    TeamNotification,
    DomainManagement,
  },
  watch: {},
  async created() {},
  mounted() {},
  computed: {},
  methods: {
    // 打开域名管理页面
    openDomainManagement() {
      this.domainManagement = true;
    },
  },
};
</script>
<!-- 5.1.0新改的样式，下面没有scoped是以前的老样式，先不动，直接覆盖 -->
<style lang="stylus" scoped>
.teamManagement
  width 100vw !important;
  max-width 100vw !important;
  height calc(100vh - 60px);
  padding 10px 0 !important;
  margin 0 !important;
  box-sizing border-box;
  background #F7F7F7;
  .domainManagementBlock
    width: 1160px;
    overflow hidden;
    margin auto;
    height: 100%;
    background: #FFFFFF;
  .settings-tabs
    width 1160px;
    overflow hidden;
    margin auto;
    display flex;
    .tabTitle
      width 100%;
      height 100%;
      display flex;
      padding 0 20px;
      align-items center;
      justify-content flex-start;
      border-bottom 1px solid #e5e5e5;
      .iconBox
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      .settingTitle
        margin-left: 10px;
        font-size: 16px;
    .managementPage
      width 100%;
      overflow hidden;
      height calc(100vh - 80px);
</style>
<style lang="stylus">
@import '~styles/utils/index';
  .teamManagement
    .el-tabs
      +e(header)
        background #ffffff
        border-top 1px solid #e5e5e5
        border-bottom 1px solid #e5e5e5
        border-left 1px solid #e5e5e5
      +e(item)
        &:last-child
          .tabTitle
            border-bottom 0
    .settings-tabs
      .el-tabs__content
        flex 1;
        min-width 0;
      .el-tabs__header
        width 282px !important;
        flex-shrink 0;
        display: flex;
        .el-tabs__nav-wrap
          flex: 1;
        .el-tabs__item
          height 58px;
          width 100%;
          display: flex;
          padding 0;
          align-items: center;
          box-sizing: border-box;
</style>
