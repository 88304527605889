<template>
  <div class="showVideoContent">
    <div class="uploadStatus" v-if="videoProgress != 100">
      <el-progress
        :width="46"
        :stroke-width="3"
        color="#ffffff"
        type="circle"
        :percentage="videoProgress"
      ></el-progress>
      <div class="showOffStatus">
        <div class="uploadIcon">
          <span class="iconfont_Me icon-Turnup uploadIconStyle"></span>
        </div>
        <div class="showOffFilesize">
          {{ uploadedFilesize }}/{{ totalFilesize }}
        </div>
      </div>
    </div>
    <div
      class="deleteThisVideo cancleStyle"
      @click.stop="$emit('needCancleVideo')"
      v-if="videoProgress < 100"
    >
      <span class="iconfont_Me icon-x deleteStyle"></span>
    </div>
    <div
      class="deleteThisVideo"
      @click.stop="$emit('needDeleteVideo')"
      v-if="videoProgress == 100"
    >
      <span class="iconfont_Me icon-x deleteStyle"></span>
    </div>
    <VideoPlayer
      :config="videoConfig"
      v-if="videoProgress == 100"
      @getDuration="getDuration"
    ></VideoPlayer>
  </div>
</template>
<script>
import VideoPlayer from "../../../../components/videoPlayer/smallVersion";
export default {
  components: {
    VideoPlayer,
  },
  props: {
    uploadedVideo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    // 监听是否获取到了上传的视频网络地址
    "uploadedVideo.path": {
      handler(val) {
        if (val) {
          this.$set(this.videoConfig, "path", val);
          this.$forceUpdate();
        }
      },
      deep: true,
    },
    // 监听上传进度
    "uploadedVideo.progress": {
      handler(val) {
        if (val) {
          this.totalFilesize = this.changeLimit(this.uploadedVideo.size);
          this.uploadedFilesize = this.changeLimit(
            this.uploadedVideo.size * (Math.round(Number(val)) / 100)
          );
          this.videoProgress = Math.round(Number(val));
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      // 视频配置
      videoConfig: {
        path: "",
        type: "small",
        // 当前播放进度
        currentTime: 0,
        // 音量控制按钮是否展示
        volumeControl: true,
      },
      // 上传进度存储
      videoProgress: 0,
      // 当前已上传的大小
      uploadedFilesize: "0B",
      // 文件总大小
      totalFilesize: "0B",
    };
  },
  methods: {
    // 获取视频的总时长
    getDuration(duration) {
      this.$emit("getDuration", duration);
    },
  },
};
</script>
<style lang="stylus" scoped>
.showVideoContent
  min-height 204px;
  width 400px;
  overflow hidden;
  border-radius 10px;
  margin-bottom 15px;
  position relative;
  background rgba(0,0,0,0.9);
  .uploadStatus
    width 100%;
    height 100%;
    position absolute;
    top 0;
    left 0;
    display flex;
    align-items center;
    justify-content center;
    background url('./grounGlass.png') no-repeat;
    background-size 100% 100%;
    .showOffStatus
      width 100%;
      height 24px;
      padding 0 10px;
      display flex;
      align-items center;
      justify-content space-between;
      position absolute;
      bottom 8px;
      left 0;
      .uploadIcon
        width 24px;
        height 24px;
        display flex;
        border-radius 50%
        align-items center;
        justify-content center;
        background rgba(0,0,0,0.5);
        .uploadIconStyle
          color #ffffff;
          font-size 18px;
      .showOffFilesize
        height 20px;
        padding 0 10px;
        font-size 12px;
        color #ffffff;
        line-height 20px;
        background rgba(0,0,0,0.5);
  .deleteThisVideo
    top 5px;
    right 5px;
    width 18px;
    height 18px;
    z-index 3;
    border-radius 50%;
    position absolute;
    background rgba(0,0,0,0.2);
    display flex;
    align-items center;
    justify-content center;
    cursor pointer;
    .deleteStyle
      font-size 12px;
      color #ffffff;
  .cancleStyle{
    background: red;
  }
</style>
<style lang="stylus">
.uploadStatus
  .el-progress-circle
    background rgba(0,0,0,0.4);
    .el-progress-circle__track
      stroke: rgba(255,255,255,0.4);;
  .el-progress__text
    font-size 14px;
    color #fff;
</style>
