var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "memberInfoDialog",
      attrs: {
        visible: _vm.showMemberRecordDialog,
        width: "540px",
        "close-on-click-modal": false,
        "before-close": _vm.closeMemberRecordDialog,
        "destroy-on-close": true,
        top: "0"
      },
      on: {
        "update:visible": function($event) {
          _vm.showMemberRecordDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogHeader" }, [
        _vm.selectedRow && _vm.selectedRow.profilePhoto
          ? _c(
              "div",
              { staticClass: "avatarBox" },
              [
                _c("Avatar", {
                  attrs: {
                    path: _vm.selectedRow.profilePhoto,
                    international:
                      _vm.selectedRow.country == ""
                        ? 0
                        : _vm.toNumber(_vm.selectedRow.country),
                    isFrozen: _vm.selectedRow.freezeStatus,
                    isOwnerOfTheTeam:
                      _vm.selectedRow.identity == 4 ? true : false
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.selectedRow
          ? _c(
              "div",
              { staticClass: "memberInfo" },
              [
                _c(
                  "div",
                  {
                    staticClass: "memberName",
                    attrs: { title: _vm.selectedRow.name }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.selectedRow.name) + "\n      "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "memberId commonStyle",
                    attrs: { title: _vm.selectedRow.ucId }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../../assets/images/teamManagement/melinkedID_smart.png")
                      }
                    }),
                    _vm._v(
                      "\n        " + _vm._s(_vm.selectedRow.ucId) + "\n      "
                    )
                  ]
                ),
                _vm.selectedRow.emails.length > 0
                  ? _vm._l(
                      _vm.reorderArrayByCondition(_vm.selectedRow.emails, 3),
                      function(item) {
                        return _c(
                          "div",
                          {
                            staticClass: "memberEmail commonStyle",
                            attrs: { title: item.mailAddress }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../../assets/images/teamManagement/email_smart.png")
                              }
                            }),
                            _vm._v(
                              "\n          " +
                                _vm._s(item.mailAddress) +
                                "\n        "
                            )
                          ]
                        )
                      }
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "placeholder" })
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.recordLoading,
              expression: "recordLoading"
            }
          ],
          staticClass: "dialogBody",
          attrs: {
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "#FFFFFF"
          }
        },
        [
          _vm._l(_vm.listData, function(item) {
            return _c("div", { key: item.recordTime, staticClass: "item" }, [
              _c(
                "span",
                {
                  staticClass: "span_l",
                  style: { color: "#292D32", "font-size": "16px" },
                  attrs: { title: _vm.itemTitle(item) }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.itemTitle(item)) + "\n      "
                  )
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "span_r",
                  style: { color: "#858585", "font-size": "16px" }
                },
                [_vm._v(_vm._s(item.recordTime))]
              )
            ])
          }),
          _vm.listData.length == 0
            ? _c(
                "div",
                { staticClass: "placeholderMap" },
                [_c("PlaceholderMap", { attrs: { placeholderType: 2 } })],
                1
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }