<template>
  <div class="managementList">
    <div class="managementHeader">
      <div class="headerleft">
        {{ groupName }}
        <span
          class="iconfont_Me icon-edit1 iconStyle"
          @click="openChangeTeamNameDialog"
        ></span>
      </div>
      <div class="headerRight" v-if="notVerifying" @click="startVerify">
        <div class="startVerify">
          <img
            :style="{ width: '24px', height: '24px' }"
            :src="
              require('../../../assets/images/teamManagement/email_icon.png')
            "
          />
          <div class="activateBusinessEmail">
            {{ $t("activateBusinessEmailServices") }}
          </div>
          <span class="iconfont_Me icon-right iconStyle"></span>
        </div>
      </div>
      <div class="headerRight" @click.stop="goDomainManagement" v-else>
        @{{ mailDomain }}
        <span class="verifying" v-if="isVerifying && !emailMigrationInProgress">
          {{ $t("isVerifying") }}
        </span>
        <span class="verifying" v-if="emailMigrationInProgress">
          {{ $t("emailMigrationInProgress") }}
        </span>
        <span class="iconfont_Me icon-right iconStyle"></span>
      </div>
    </div>
    <div
      class="managementBox"
      v-loading="teamLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#FFFFFF"
    >
      <div class="searchAndAddBox">
        <div class="searchBox">
          <el-input
            resize="none"
            maxlength="200"
            v-model="searchText"
            :placeholder="placeholderIcon"
            @input="searchChange"
          ></el-input>
        </div>
        <div class="addBox" @click="openCreateMemberMethodDialog">
          <img
            :src="
              require('../../../assets/images/teamManagement/addMember.png')
            "
          />
        </div>
      </div>
      <MembersTable
        :members="members"
        :mailDomain="mailDomain"
        @openMemberInfoDialog="openMemberInfoDialog"
        @openCreatedEmailOrIDSuccessDialog="
          openCreatedEmailOrIDSuccess_method_1
        "
        @openMemberRecordDialog="openMemberRecordDialog"
        @changeFreezeStatus="changeFreezeStatus"
        @unbindEmail="unbindEmail"
      ></MembersTable>
    </div>
    <div class="membersPagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="dataTotal"
        @current-change="currentChange"
        :current-page.sync="currentPage"
      >
      </el-pagination>
    </div>
    <template v-if="createMemberMethodDialog">
      <CreateMemberMethodDialog
        :createMemberMethodDialog="createMemberMethodDialog"
        @closeCreateMemberMethodDialog="closeCreateMemberMethodDialog"
      ></CreateMemberMethodDialog>
    </template>
    <template v-if="showChangeTeamNameDialog">
      <ChangeTeamNameDialog
        @closeChangeTeamNameDialog="closeChangeTeamNameDialog"
        :showChangeTeamNameDialog="showChangeTeamNameDialog"
        :groupNameInDialog="groupNameInDialog"
        @changeTeamName="changeTeamName"
      ></ChangeTeamNameDialog>
    </template>
    <template v-if="showAddMemberDialog">
      <AddMemberDialog
        :showAddMemberDialog="showAddMemberDialog"
        @closeAddMemberDialog="closeAddMemberDialog"
      ></AddMemberDialog>
    </template>
    <template v-if="showMemberInfoDialog">
      <MemberInfoDialog
        :showMemberInfoDialog="showMemberInfoDialog"
        :selectedRow="selectedRow"
        @closeMemberInfoDialog="closeMemberInfoDialog"
      ></MemberInfoDialog>
    </template>
    <template v-if="showMemberRecordDialog">
      <MemberRecordDialog
        :showMemberRecordDialog="showMemberRecordDialog"
        :selectedRow="selectedRow"
        @closeMemberRecordDialog="closeMemberRecordDialog"
      ></MemberRecordDialog>
    </template>
    <template v-if="showFreezeStatusDialog">
      <FreezeStatusDialog
        :showFreezeStatusDialog="showFreezeStatusDialog"
        :selectedRow="selectedRow"
        @closeFreezeStatusDialog="closeFreezeStatusDialog"
      ></FreezeStatusDialog>
    </template>
    <template v-if="createIDAndEmailDialog">
      <CreateIDAndEmailDialog
        :createIDAndEmailDialog="createIDAndEmailDialog"
        @closeCreateIDAndEmailDialog="closeCreateIDAndEmailDialog"
        @confirm="createIDAndEmailConfirm"
      ></CreateIDAndEmailDialog>
    </template>
    <template v-if="myTeamMembersDialog">
      <MyTeamMembersDialog
        :myTeamMembersDialog="myTeamMembersDialog"
        @closeMyTeamMembersDialog="closeMyTeamMembersDialog"
        @selectMember="selectMember"
      ></MyTeamMembersDialog>
    </template>
    <template v-if="createEmailForMemberDialog">
      <CreateEmailForMemberDialog
        :createEmailForMemberDialog="createEmailForMemberDialog"
        :memberInfo="createEmail_memberInfo"
        @closeCreateEmailForMemberDialog="closeCreateEmailForMemberDialog"
        @confirm="createEmailForMemberConfirm"
      ></CreateEmailForMemberDialog>
    </template>
    <template v-if="createdEmailOrIDSuccessDialog">
      <CreatedEmailOrIDSuccessDialog
        :from="createdEmailOrIDSuccessFrom"
        :memberInfo="createdEmailOrIDSuccessMemberInfo"
        :createdEmailOrIDSuccessDialog="createdEmailOrIDSuccessDialog"
        @closeCreatedEmailOrIDSuccessDialog="closeCreatedEmailOrIDSuccessDialog"
        @sendNotifications="sendNotifications"
      ></CreatedEmailOrIDSuccessDialog>
    </template>
    <template v-if="notificationForMemberDialog">
      <NotificationForMemberDialog
        :notificationForMemberDialog="notificationForMemberDialog"
        :notificationInfo="notificationForMemberInfo"
        @closeNotificationForMemberDialog="notificationForMemberDialog = false"
      ></NotificationForMemberDialog>
    </template>
  </div>
</template>
<script>
import { unbindEmailForMember, getTeamInfo } from "@/api/newVersion/team";
import ChangeTeamNameDialog from "./dialog/changeTeamNameDialog.vue";
import MembersTable from "./membersTable.vue";
import CreateMemberMethodDialog from "./dialog/createMemberMethod.vue";
import AddMemberDialog from "./dialog/addMemberDialog.vue";
import MemberInfoDialog from "./dialog/memberInfoDialog.vue";
import MemberRecordDialog from "./dialog/memberRecordDialog.vue";
import FreezeStatusDialog from "./dialog/freezeStatusDialog.vue";
import CreateIDAndEmailDialog from "./dialog/createIDAndEmailDialog.vue";
import MyTeamMembersDialog from "./dialog/myTeamMembers.vue";
import CreateEmailForMemberDialog from "./dialog/createEmailForMember.vue";
import CreatedEmailOrIDSuccessDialog from "./dialog/createdEmailOrIDSuccess.vue";
import NotificationForMemberDialog from "./dialog/notificationForMember.vue";
export default {
  name: "ManagementList",
  components: {
    CreateMemberMethodDialog,
    ChangeTeamNameDialog,
    MembersTable,
    AddMemberDialog,
    MemberInfoDialog,
    MemberRecordDialog,
    FreezeStatusDialog,
    CreateIDAndEmailDialog,
    MyTeamMembersDialog,
    CreateEmailForMemberDialog,
    CreatedEmailOrIDSuccessDialog,
    NotificationForMemberDialog,
  },
  data() {
    return {
      groupName: "",
      groupNameInDialog: "",
      members: [],
      // 修改团队名称
      showChangeTeamNameDialog: false,
      // 搜索框默认文本
      placeholderIcon: "\ue6af",
      searchText: "",
      searchTimer: null,
      // 添加成员
      showAddMemberDialog: false,
      teamLoading: false,
      pageSize: 20,
      dataTotal: 0,
      currentPage: 1,
      // 展示成员信息
      showMemberInfoDialog: false,
      selectedRow: null,
      // 展示成员记录
      showMemberRecordDialog: false,
      // 展示冻结状态
      showFreezeStatusDialog: false,
      // 正在验证域名
      isVerifying: false,
      // 创建成员的方式
      createMemberMethodDialog: false,
      // 创建ID和邮箱
      createIDAndEmailDialog: false,
      // 我的团队成员
      myTeamMembersDialog: false,
      // 为成员创建邮箱
      createEmailForMemberDialog: false,
      // 需要创建邮箱的成员信息
      createEmail_memberInfo: null,
      // 邮箱或ID创建成功
      createdEmailOrIDSuccessDialog: false,
      // 创建邮箱或ID成功的来源
      createdEmailOrIDSuccessFrom: "method_1",
      // 创建邮箱或ID成功的成员信息
      createdEmailOrIDSuccessMemberInfo: null,
      // 通知成员
      notificationForMemberDialog: false,
      // 通知成员信息
      notificationForMemberInfo: null,
      // 没有验证过域名
      notVerifying: true,
      // 邮箱域名
      mailDomain: "",
      // 域名验证步骤
      mailVerifyStep: null,
      // 邮箱是否迁移状态
      migrate: false,
      // 记录初始化的二进制高位记录
      binaryHighBitRecords: null,
    };
  },
  computed: {
    // 域名是否已审核
    verifiedDomain() {
      return !this.isVerifying && !this.notVerifying;
    },
    // 邮箱是否在迁移中
    emailMigrationInProgress() {
      // 只有当进度步骤小于第八步，并且处于迁移状态或者是再次验证邮箱但是已经完成第四步后，才显示迁移中
      return (
        this.mailVerifyStep &&
        this.mailVerifyStep.currentStep < 8 &&
        (this.migrate || this.fourthStepHasBeenCompleted_onceAgain)
      );
    },
    // 刷新团队信息
    refreshTeamInfo() {
      return this.$store.state.team.refreshTeamInfo;
    },
    // 主动打开创建成员方式弹窗
    init_createMemberMethodDialog() {
      return this.$store.state.team.openCreateMemberMethodDialog;
    },
    // 再次验证邮箱
    onceAgain() {
      if (typeof this.binaryHighBitRecords == "string") {
        return this.binaryHighBitRecords.substr(2, 2) == "10";
      } else {
        return false;
      }
    },
    // 再次验证邮箱并且已完成第四步
    fourthStepHasBeenCompleted_onceAgain() {
      // 当已开启再次验证邮箱，并且已经完成第四步
      if (this.onceAgain && typeof this.binaryHighBitRecords == "string") {
        return this.binaryHighBitRecords.substr(2, 3) == "101";
      } else {
        return false;
      }
    },
  },
  destroyed() {
    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  },
  props: {
    activeName: {
      type: String,
      default: "",
    },
  },
  watch: {
    activeName: {
      async handler(val) {
        if (val == "myTeam") {
          // 重置一下data数据
          Object.assign(this.$data, this.$options.data.call(this));
          this.$nextTick(async () => {
            await this.getTeamInfo();
          });
        }
      },
      deep: true,
    },
    // 刷新团队信息
    refreshTeamInfo: {
      async handler(val, old) {
        if (val && val != old) {
          this.$store.commit("setRefreshTeamInfo", false);
          await this.getTeamInfo();
        }
      },
      deep: true,
    },
    init_createMemberMethodDialog: {
      async handler(val, old) {
        if (val && val != old) {
          this.createMemberMethodDialog = true;
          this.$store.commit("setOpenCreateMemberMethodDialog", false);
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getTeamInfo();
    const first_login = window.localStorage.getItem(
      "memberRegister_first_time"
    );
    if (first_login) {
      this.$store.commit("setConfirmTipsDialog", {
        showDialog: true,
        showText: this.$t("teamManagementSuccess"),
        confirmFn: () => {
          this.startVerify();
        },
      });
    }
    window.localStorage.removeItem("memberRegister_first_time");
  },
  methods: {
    // 解绑邮箱
    async unbindEmail(row) {
      // 获取管理员绑定的邮箱
      const email = row.emails.filter((email) => email.bindCondition == 3)[0];
      if (email) {
        this.$store.commit("setWarningTipsDialog", {
          showText: this.$t("unbindTeamEmail", {
            n: row.ucId,
            m: email.mailAddress,
          }),
          showDialog: true,
          confirmFn: async () => {
            const result = await unbindEmailForMember({
              email: email.mailAddress,
              memberId: row.providerId,
            });
            if (result.code == 200) {
              await this.getTeamInfo();
            } else {
              this.$message({
                type: "error",
                message: result.message,
              });
            }
          },
        });
      }
    },
    // 开始验证
    startVerify() {
      this.$store.commit("setVerifyDomainDialog", {
        verifyStep: this.mailVerifyStep,
        domain: this.mailDomain,
        migrate: this.migrate,
        binaryHighBitRecords: this.binaryHighBitRecords,
      });
    },
    // 发送通知
    sendNotifications(info) {
      this.notificationForMemberInfo = info;
      this.notificationForMemberDialog = true;
    },
    goDomainManagement() {
      if (!this.isVerifying && !this.emailMigrationInProgress) {
        this.$emit("openDomainManagement");
      } else {
        this.startVerify();
      }
    },
    async currentChange(page) {
      this.currentPage = page;
      await this.getTeamInfo();
    },
    async getTeamInfo(
      params = {
        pageSize: this.pageSize,
        page: this.currentPage,
        searchWords: this.searchText,
      }
    ) {
      this.teamLoading = true;
      const result = await getTeamInfo(params);
      this.teamLoading = false;
      if (result.code == 200) {
        let {
          groupName,
          members,
          totalMembers,
          mailStatus,
          mailDomain,
          mailRegisterStep,
          migrate,
        } = result.data.data;
        if (members.length == 0) {
          this.members = [];
          this.dataTotal = 0;
          return;
        }
        if (this.searchText == "") {
          this.groupName = groupName;
          this.groupNameInDialog = groupName;
        }
        this.members = members;
        this.dataTotal = Number(totalMembers);
        // 没有验证过域名
        this.notVerifying = !mailDomain && !mailStatus;
        // 域名正在验证中
        this.isVerifying = mailDomain && !mailStatus;
        this.mailDomain = mailDomain;
        this.migrate = migrate;
        if (!mailRegisterStep) {
          mailRegisterStep = 0;
        }
        this.mailVerifyStep = this.processDecimal(mailRegisterStep);
        const teamDomainInfo = {
          mailStatus,
          mailDomain,
          mailRegisterStep,
        };
        this.$store.commit("setTeamDomainInfo", teamDomainInfo);
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
    // 处理步骤二进制
    processDecimal(decimalNumber) {
      let binaryString = decimalNumber.toString(2).padStart(12, "0");
      let currentStep = parseInt(binaryString.slice(-4), 2);
      this.binaryHighBitRecords = binaryString.slice(0, -4);
      let completedSteps = [];
      let incompleteSteps = [];
      for (let i = binaryString.length - 5; i >= 0; i--) {
        if (binaryString[i] === "1") {
          completedSteps.push(binaryString.length - i - 5 + 1);
        } else {
          incompleteSteps.push(binaryString.length - i - 5 + 1);
        }
      }
      return {
        currentStep: currentStep,
        completedSteps: completedSteps,
        incompleteSteps: incompleteSteps,
      };
    },
    // 选中现有成员
    selectMember(member) {
      this.createEmailForMemberDialog = true;
      this.createEmail_memberInfo = member;
    },
    // 关闭为成员创建邮箱弹窗
    closeCreateEmailForMemberDialog() {
      this.createEmailForMemberDialog = false;
      this.createEmail_memberInfo = null;
    },
    // 为成员创建邮箱成功
    async createEmailForMemberConfirm(memberInfo) {
      await this.getTeamInfo();
      await this.$nextTick();
      this.createdEmailOrIDSuccessDialog = true;
      this.createdEmailOrIDSuccessFrom = "method_2";
      this.createdEmailOrIDSuccessMemberInfo = memberInfo;
    },
    // 关闭为成员创建邮箱成功弹窗
    closeCreatedEmailOrIDSuccessDialog() {
      this.createdEmailOrIDSuccessDialog = false;
    },
    // 关闭我的团队成员弹窗
    closeMyTeamMembersDialog() {
      this.myTeamMembersDialog = false;
    },
    openChangeTeamNameDialog() {
      this.showChangeTeamNameDialog = true;
    },
    closeChangeTeamNameDialog() {
      this.showChangeTeamNameDialog = false;
    },
    changeTeamName(text) {
      this.groupName = text;
    },
    openAddMemberDialog() {
      this.showAddMemberDialog = true;
    },
    openCreateMemberMethodDialog() {
      this.createMemberMethodDialog = true;
    },
    async createIDAndEmailConfirm(memberInfo) {
      await this.getTeamInfo();
      await this.$nextTick();
      this.openCreatedEmailOrIDSuccess_method_1(memberInfo);
    },
    openCreatedEmailOrIDSuccess_method_1(memberInfo) {
      this.createdEmailOrIDSuccessDialog = true;
      this.createdEmailOrIDSuccessFrom = "method_1";
      this.createdEmailOrIDSuccessMemberInfo = memberInfo;
    },
    closeCreateIDAndEmailDialog() {
      this.createIDAndEmailDialog = false;
    },
    closeCreateMemberMethodDialog(itemValue = null) {
      if (typeof itemValue == "number") {
        switch (itemValue) {
          // 只创建账号
          case 0:
            this.openAddMemberDialog();
            break;
          // 创建Melinked ID和邮箱
          case 1:
            if (this.notVerifying) {
              this.guideToReviewDomain();
            }
            // 当走迁移流程时，完成所有权验证后，便可创建邮箱账号
            if (this.migrate) {
              if (!this.mailVerifyStep.completedSteps.includes(3)) {
                this.domainReviewInProgress();
              } else {
                this.createIDAndEmailDialog = true;
              }
            } else {
              if (this.isVerifying) {
                this.domainReviewInProgress();
              }
              if (this.verifiedDomain) {
                this.createIDAndEmailDialog = true;
              }
            }
            break;
          // 为现有Melinked ID创建邮箱
          case 2:
            if (this.notVerifying) {
              this.guideToReviewDomain();
            }
            // 当走迁移流程时，完成所有权验证后，便可创建邮箱账号
            if (this.migrate) {
              if (!this.mailVerifyStep.completedSteps.includes(3)) {
                this.domainReviewInProgress();
              } else {
                this.myTeamMembersDialog = true;
              }
            } else {
              if (this.isVerifying) {
                this.domainReviewInProgress();
              }
              if (this.verifiedDomain) {
                this.myTeamMembersDialog = true;
              }
            }
            break;
        }
      }
      this.createMemberMethodDialog = false;
    },
    // 引导用户添加域名
    guideToReviewDomain() {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("guideToReviewDomain"),
        showDialog: true,
        confirmFn: () => {
          this.openDomainReview();
        },
      });
    },
    // 域名正在审核中
    domainReviewInProgress() {
      this.$store.commit("setWarningTipsDialog", {
        showText: this.$t("domainReviewInProgress"),
        showDialog: true,
        confirmFn: () => {
          this.openDomainReview();
        },
      });
    },
    // 进入域名审核窗口
    openDomainReview() {
      this.startVerify();
    },
    async closeAddMemberDialog(refresh = false, row) {
      this.showAddMemberDialog = false;
      if (refresh) {
        await this.getTeamInfo();
      }
      if (row) {
        this.openMemberInfoDialog(row);
      }
    },
    async searchChange() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(async () => {
        await this.getTeamInfo();
      }, 500);
    },
    openMemberInfoDialog(row) {
      if (row.identity !== 4) {
        this.selectedRow = row;
        this.showMemberInfoDialog = true;
      }
    },
    closeMemberInfoDialog() {
      this.selectedRow = null;
      this.showMemberInfoDialog = false;
    },
    openMemberRecordDialog(row) {
      if (row.identity !== 4) {
        this.selectedRow = row;
        this.showMemberRecordDialog = true;
      }
    },
    closeMemberRecordDialog() {
      this.selectedRow = null;
      this.showMemberRecordDialog = false;
    },
    changeFreezeStatus(row) {
      if (row.identity !== 4) {
        this.selectedRow = row;
        this.showFreezeStatusDialog = true;
      }
    },
    async closeFreezeStatusDialog(refresh = false) {
      this.selectedRow = null;
      this.showFreezeStatusDialog = false;
      if (refresh) {
        await this.getTeamInfo();
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.managementList
  width: 100%;
  height: 100%;
  overflow hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .managementHeader
    width: 100%;
    min-height: 60px;
    height: 4.6875vw;
    max-height: 90px;
    padding 0 35px;
    box-sizing: border-box;
    background: linear-gradient(131deg,#f2fcf8 3%, #f7fcf2 50%, #f2fbfc 98%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
    flex-shrink: 0;
    .headerleft
      color #333333;
      font-size: 18px;
      font-weight: 400;
      .iconStyle
        cursor pointer;
        color: #333333;
        font-size: 20px;
        margin-left: 5px;
    .headerRight
      font-size: 16px;
      color: #292D32;
      line-height: 22px;
      cursor: pointer;
      .startVerify
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img
          flex-shrink: 0;
        .activateBusinessEmail
          line-height: 24px;
          font-size: 18px;
          color: #292D32;
          flex: 1;
          margin: 0 6px;
      .verifying
        height: 22px;
        color: #FFFFFF;
        font-size: 14px;
        padding: 0 4px;
        background: #F39945;
  .managementBox
    width: 100%;
    overflow hidden;
    flex 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .searchAndAddBox
      width: 100%;
      height: 92px;
      flex-shrink: 0;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchBox
        width: 293px;
        height: 38px;
        border-radius: 19px;
        margin-left: 35px;
        overflow hidden;
      .addBox
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: #33CC66
        margin-right: 35px;
        cursor pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img
          width: 26px;
          height: 26px;
  .membersPagination
    width: 100%;
    height: 65px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
</style>
<style lang="stylus">
.managementList
  .managementBox
    .searchAndAddBox
      .searchBox
        .el-input
          background: #F2F2F2 !important;
          .el-input__inner
            border none !important;
            font-family 'iconfont_Me';
            caret-color #33CC66;
            font-size 14px !important;
            color #333333 !important;
            background: #F2F2F2 !important;
            &::placeholder
              font-size: 16px
  .membersPagination
    .el-pagination
      .btn-prev
        height 40px !important;
        border-radius 50% !important;
      .btn-next
        height 40px !important;
        border-radius 50% !important;
      .el-pager
        li
          height 40px !important;
          line-height: 40px !important;
          border-radius 50% !important;
</style>
